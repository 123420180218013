import React from 'react'

export default function Footer(params) {
  return (
    <>

        <div className='footer-logo navbar-border ml-0 text-center'>
             <img
                 className='img-responsive text-center footer-img-logo'
                 src={params.src}
                 alt={params.alt}
             />
         </div> 
      
    </>
  )
}
