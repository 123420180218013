import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Presolicitud from "./views/Presolicitud/Presolicitud";
import Solicitud from "./views/Solicitud/Solicitud";

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Routes>
          <Route path="/:codigoSucursal" element={ <Presolicitud /> } ></Route>
          <Route path="/solicitud" element={ <Solicitud /> }></Route>
          <Route path="/*" element={ <Presolicitud /> } ></Route>
          
        </Routes>
      </BrowserRouter>

    </div>
  );
}


export default App;