import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';

//Se exporta el state que se usará para mnostrar el spinner o quitarlo
export const useAuthStore = () => {

    const { estadoCargando } = useSelector( state => state.auth );
    
    return {

        estadoCargando

    }

}