//React
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { useNavigate } from 'react-router-dom';

//Librerias
import Select from "react-select";
import { Modal } from "react-bootstrap";
//import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";

//Funciones Hooks
import { usePresolicitudStore } from "../../hooks";

//Components
import HeaderLogo from "../../sharedComponents/HeaderLogo";
import HeaderImagen from "../../sharedComponents/HeaderImagen";
import Footer from "../../sharedComponents/Footer";

//Spinner component
import { useAuthStore } from "../../hooks";
import SpinnerCarga from "../../sharedComponents/SpinnerCarga";

//css
import "../../styles/css/views/containerForm.css";
import "../../styles/css/components/_header.css";
import "../../styles/css/components/_footer.css";

//imagenes
import banner from "../../styles/assets/Banner-Form-Flujo-Credito-CrediE.png";
import bannerMobile from "../../styles/assets/Banner-Form-Flujo-Credito-CrediE-Movil.png";
import logo from "../../styles/assets/logo-credie.png";

//Vista para el primer formulario
export default function Presolicitud() {
  // const navigate = useNavigate();

  const { codigoSucursal } = useParams();

  //Estado para mostrar o no el spinner
  const { estadoCargando } = useAuthStore();

  //Importación función para cargar datos de los Selects
  const {
    listFirstDataSelects,
    dataCedula,
    dataCedulaNumeroAsegurado,
    sendDataPreSolicitud,
  } = usePresolicitudStore();

  //State para mostrar modal spinner al cargar datos de la cedula
  const [lgShow, setLgShow] = useState(false);
  const lgHandleClose = () => setLgShow(false);

  //State para modal del boton Enviar Solicitud
  const [lgShowRespuestaPresolicitud, setLgShowRespuestaPresolicitud] =
    useState(false);
  const lgHandleCloseRespuestaPresolicitud = () =>
    setLgShowRespuestaPresolicitud(false);

  //State para verificar si se tiene una sucursal por codigo QR o no
  const [bitSucursal, setBitSucursal] = useState(false);
  //const [decodedValue, setDecodedValue] = useState('');

  //States con datos
  const [dataApiCedula, setDataApiCedula] = useState(false);
  const [dataApiNumeroAsegurado, setDataApiNumeroAsegurado] = useState(false);
  const [sucursal, setSucursal] = useState("");
  //Se comentan las siguientes lineas ya que si se encuentra la sucursal por codigo QR no se debe mostrar ningun input con la info de dicha sucursal encontrada
  //const [nombreSucursal, setNombreSucursal] = useState('');
  const [nombre, setNombre] = useState("");
  const [bitDataApiNombre, setBitDataApiNombre] = useState(false);
  const [numeroAsegurado, setNumeroAsegurado] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [bitDataApiApellido1, setBitDataApiApellido1] = useState(false);
  const [apellido2, setApellido2] = useState("");
  const [bitDataApiApellido2, setBitDataApiApellido2] = useState(false);
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [bitDataApiFechaNacimiento, setBitDataApiFechaNacimiento] =
    useState(false);
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);
  const [provincias, setProvincias] = useState([""]);
  const [canton, setCanton] = useState([""]);
  const [textProvincia, setTextProvincia] = useState("");
  const [textCanton, setTextCanton] = useState("");
  const [distrito, setDistrito] = useState([""]);
  const [textDistrito, setTextDistrito] = useState("");
  const [barrio, setBarrio] = useState([""]);
  const [textBarrio, setTextBarrio] = useState("");
  const [poblado, setPoblado] = useState([""]);
  const [textPoblado, setTextPoblado] = useState("");
  const [idPoblado, setIdPoblado] = useState("");
  const [bitActividadEconomica, setBitActividadEconomica] =
    useState("Carga Inicial");
  //const [textBitActividadEconomica,setTextBitActividadEconomica ] = useState('En espera de cedula ingresada');
  const [telefono, setTelefono] = useState("");
  const [genero, setGenero] = useState("");

  //Valores listados para ser elegidos
  const [listSucursal, setListSucursal] = useState([]);
  const [listCanton, setListCanton] = useState([]);
  const [listDistrito, setListDistrito] = useState([]);
  const [listBarrio, setListBarrio] = useState([]);
  const [listPoblado, setListPoblado] = useState([]);
  const [listActividadEconomica, setListActividadEconomica] = useState([]);
  const [listTipoIdentificacion, setListTipoIdentificacion] = useState([]);

  //Valores seleccionados
  const [
    selectedOptionTipoIdentificacion,
    setSelectedOptionTipoIdentificacion,
  ] = useState();
  const [valueTipoIdentificacion, setValueTipoIdentificacion] = useState(1);

  const [inputValueCedula, setInputValueCedula] = useState("");

  //State para definir si se recibieron datos personales del API o no
  const [bitDatosCedula, setBitDatosCedula] = useState(false);

  //Opciones para Select de Tipo de Credito, cuando es tipo No Asalariado
  const optionNoAsalariado = [
    { value: "opcionAmaDeCasa", label: "Ama de casa" },
    {
      value: "opcionEscogerActividadEconomica",
      label: "Escoger una actividad económica",
    },
  ];

  const [valueIdActividadEconomica, setValueIdActividadEconomica] =
    useState("");

  const [textTipoCredito, setTextTipoCredito] = useState("");

  //state para validaciones de errores al enviar datos para crear la solicitud
  const [errorSucursal, setErrorSucursal] = useState("");
  const [errorIdentificacion, setErrorIdentificacion] = useState("");
  const [errorNumeroAsegurado, setErrorNumeroAsegurado] = useState("");
  const [errorNombre, setErrorNombre] = useState("");
  const [errorApellido1, setErrorApellido1] = useState("");
  const [errorApellido2, setErrorApellido2] = useState("");
  const [errorFechaNacimiento, setErrorFechaNacimiento] = useState("");
  //const [errorEmailValidado, setErrorEmailValidado] = useState('');
  const [errorProvincia, setErrorProvincia] = useState("");
  const [errorCanton, setErrorCanton] = useState("");
  const [errorDistrito, setErrorDistrito] = useState("");
  const [errorBarrio, setErrorBarrio] = useState("");
  const [errorPoblado, setErrorPoblado] = useState("");
  const [errorTipoCredito, setErrorTipoCredito] = useState("");
  const [errorActividadEconomica, setErrorActividadEconomica] = useState("");
  const [errorTelefono, setErrorTelefono] = useState("");

  const [nombreVerificacionVacio, setNombreVerificacionVacio] = useState(false);
  const [apellido1VerificacionVacio, setApellido1VerificacionVacio] =
    useState(false);
  const [apellido2VerificacionVacio, setApellido2VerificacionVacio] =
    useState(false);
  const [
    fechaNacimientoVerificacionVacio,
    setFechaNacimientoVerificacionVacio,
  ] = useState(false);

  //Función para actualizar el state elegido de la sucursal
  const handleChangeSucursal = (event) => {
    setErrorSucursal("");
    setSucursal(event.value);
  };

  //state para manejar el dinamismo de los telefonos, en caso de que se requiera
  //const [telefonos, setTelefonos] = useState('');

  //Se establece el valor del telefono
  const handleTelefonoChange = (event) => {
    setErrorTelefono("");

    const inputText = event.target.value;
    const firstDigit = inputText.charAt(0);

    if (!["6", "7", "8"].includes(firstDigit)) {
      setErrorTelefono("Debe iniciar: 6, 7 u 8");

      Swal.fire("Verifica tus datos", "Teléfono debe iniciar con 6, 7 u 8.");
    } else {
      const newValue = event.target.value;

      // Permitir solo números y el guion
      const sanitizedValue = newValue.replace(/[^0-9-]/g, "");

      const TelefonoSinGuiones = sanitizedValue.replace(/-/g, "");

      if (TelefonoSinGuiones.length < 8) {
        setErrorTelefono("Aún faltan números.");
        setTelefono(newValue);
      } else if (TelefonoSinGuiones.length > 8) {
        Swal.fire(
          "Verifica tus datos",
          "Teléfono no puede ser mayor a 8 numeros."
        );
      } else {
        setTelefono(newValue);
      }
    }
  };

  //Mascara para el input cedula nacional. Ej: 1-0123-0123
  const MaskCedulaNacional = [
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  //Mascara para el telefono. Ej: 8888-7777
  const MaskTelefono = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

  //Cantidad minima 9 porque por defecto es cedula nacional la opción de Select Tipo de identificación
  const [valorMinimoIdentificacion, setValorMinimoIdentificacion] = useState(9);
  const [valorMinimoNumeroAsegurado, setValorMinimoNumeroAsegurado] =
    useState(9);

  //Evento para cuando se digita una tecla y se evalua para ver si está permitida
  const handleKeyDownCedula = (event) => {
    // Obtener el código de la tecla presionada
    const keyCode = event.keyCode;

    // Permitir solo la tecla Borrar (Backspace)
    if (keyCode !== 8) {
      // Prevenir la acción por defecto si la tecla no es permitida
      const isSpecialChar = event.key.match(/[^\w]/g);

      if (isSpecialChar) {
        Swal.fire(
          "Verifica tus datos",
          "Solamente se permite digitar números y letras."
        );
        event.preventDefault();
      }
    }
  };

  //Función para cambiar el valor de la cedula digitada
  const handleChangeCedula = async (event) => {
    setErrorIdentificacion("");

    //Si no hay datos traidos por busqueda por numero de asegurado, se limpian inputs para que con el evento on blur
    // se traigan nuevos datos
    if (!dataApiNumeroAsegurado) {
      setNombre("");
      setApellido1("");
      setApellido2("");
      setFechaNacimiento("");
      setEmail("");
      setTelefono("");
      setValueIdActividadEconomica("");
      setTextProvincia("");
      setTextCanton("");
      setTextDistrito("");
      setTextBarrio("");
      setTextPoblado("");
      //setNumeroAsegurado('');
      setGenero("");
      setTextTipoCredito("");
      //setTextBitActividadEconomica('');

      setErrorNombre("");
      setErrorApellido1("");
      setErrorApellido2("");
      setErrorFechaNacimiento("");
      setErrorEmail("");
      setErrorTelefono("");
      setErrorActividadEconomica("");
      setErrorTipoCredito("");
      setErrorProvincia("");
      setErrorCanton("");
      setErrorDistrito("");
      setErrorBarrio("");
      setErrorPoblado("");
      setErrorNumeroAsegurado("");

      setBitDatosCedula(false);
      //setBitActividadEconomica('Carga Inicial')
    }


    let newValue = event.target.value;

    // Limitar la longitud de dígitos segun tipo de id identificación

    let valueIdTipoCedula = selectedOptionTipoIdentificacion.value;
    let numberMin;
    let numerMax;

    //Cedula nacional.
    if (valueIdTipoCedula === 1) {
      numberMin = 11; // 9 pero se suman 2 por los guiones
      numerMax = 11; // 9 pero se suman 2 por los guiones
      setValorMinimoIdentificacion(9);
    }
    //Cedula de Residencia
    else if (valueIdTipoCedula === 2) {
      numberMin = 9;
      numerMax = 12;
      setValorMinimoIdentificacion(numberMin);
    }
    //Pasaporte nacional o extranjero
    else if (valueIdTipoCedula === 3 || valueIdTipoCedula === 4) {
      numberMin = 9;
      numerMax = 11;
      setValorMinimoIdentificacion(numberMin);
    }

    if (newValue.length < numberMin) {
      // Permitir solo letras, números
      const sanitizedValue = newValue.replace(/[^a-zA-Z0-9]/g, "");

      // Actualizar el estado solo si el valor es permitido
      setInputValueCedula(sanitizedValue);

      //newValue = newValue.slice(0, numerMax);

      setErrorIdentificacion("Aún faltan digitos");
    } else if (newValue.length >= numberMin && newValue.length <= numerMax) {
      //newValue = newValue.slice(0, numerMax);

      // Permitir solo letras, números
      const sanitizedValue = newValue.replace(/[^a-zA-Z0-9]/g, "");

      // Actualizar el estado solo si el valor es permitido
      setInputValueCedula(sanitizedValue);

      //newValue = newValue.slice(0, numerMax);

      setErrorIdentificacion("");
    } else if (newValue.length > numerMax) {
      //newValue = newValue.slice(0, numerMax);

      Swal.fire(
        "Verifica tus datos",
        "No puedes digitar más de " + numerMax + " caracteres."
      );
    }
  }; // final handleChangeCedula

  //Evento de cuando se quita el focus del input Cedula
  //Se envia la cedula al api para buscar datos personales
  //Para la cedula y el numero de asegurado en el api, se envia el dato de la cedula
  //Para que busque primero solo con ese valor
  const handleBlurCedula = async (event) => {
    //Si no hay datos traidos por busqueda por numero de asegurado, permite buscar por cedula
    if (!dataApiNumeroAsegurado) {
      setDataApiCedula(false);

      let newValue = event.target.value;

      // Limitar la longitud de dígitos segun tipo de id identificación

      let valueIdTipoCedula = selectedOptionTipoIdentificacion.value;
      let numberMin;
      let numerMax;

      //Cedula nacional.
      if (valueIdTipoCedula === 1) {
        numberMin = 11; // 9 pero se suman 2 por los guiones
        numerMax = 11; // 9 pero se suman 2 por los guiones
        setValorMinimoIdentificacion(9);
      }
      //Cedula de Residencia
      else if (valueIdTipoCedula === 2) {
        numberMin = 9;
        numerMax = 12;
        setValorMinimoIdentificacion(numberMin);
      }
      //Pasaporte nacional o extranjero
      else if (valueIdTipoCedula === 3 || valueIdTipoCedula === 4) {
        numberMin = 9;
        numerMax = 11;
        setValorMinimoIdentificacion(numberMin);
      }

      if (newValue.length > numerMax) {
        newValue = newValue.slice(0, numerMax);

        Swal.fire(
          "Verifica tus datos",
          "Número máximo de caracteres alcanzados."
        );
      }

      // Permitir solo letras, números y espacios
      const sanitizedValue = newValue.replace(/[^a-zA-Z0-9]/g, "");

      // Actualizar el estado solo si el valor es permitido
      setInputValueCedula(sanitizedValue);

      setErrorIdentificacion("");

      if (event.target.value.length >= numberMin) {
        const cedulaSinGuiones = newValue.replace(/-/g, "");

        const data = await getDataCedula(cedulaSinGuiones);

        //Si la respuesta del api es undefined, se limpian los datos
        if (data === undefined) {
          return;
        }
        //Si la respuesta del api es success, se setean los datos
        else if (data.respuesta_tipo === "success") {
          setDataApiCedula(true);

          //Nombre
          if (data.data.persona.per_nombre !== "") {
            setNombre(data.data.persona.per_nombre);
            setBitDataApiNombre(true);
          } else {
            setNombre("");
          }

          //Apellido 1
          if (data.data.persona.per_apellido1 !== "") {
            setApellido1(data.data.persona.per_apellido1);
            setBitDataApiApellido1(true);
          } else {
            setApellido1("");
          }

          //Apellido 2
          if (data.data.persona.per_apellido2 !== "") {
            setApellido2(data.data.persona.per_apellido2);
            setBitDataApiApellido2(true);
          } else {
            setApellido2("");
          }

          //Fecha de nacimiento
          if (data.data.persona.per_fecha_nac !== "") {
            setFechaNacimiento(data.data.persona.per_fecha_nac);
            setBitDataApiFechaNacimiento(true);
          } else {
            setFechaNacimiento("");
          }

          //Localidad - Se toma como base para inicializar 
          if(data.data.persona.id_localizacion) { 
            var dist = distrito?.find(c => c.id_localizacion === data.data.persona.id_localizacion);
            var cant = canton?.find(c => c.id_localizacion === dist?.loc_nodo_padre);
            var prov = provincias?.find(c => c.id_localizacion === cant?.loc_nodo_padre);
            
            if(prov){
              setTextProvincia({ value: prov?.id_localizacion, label: prov?.loc_nombre} );
  
              setListCanton(canton.filter(c => c.loc_nodo_padre===cant.loc_nodo_padre).map((item) => ({
                value: item.id_localizacion,
                label: item.loc_nombre
              })))
              setTextCanton({ value: cant.id_localizacion, label: cant.loc_nombre})
  
              setListDistrito(distrito.filter(c => c.loc_nodo_padre===dist.loc_nodo_padre).map((item) => ({
                value: item.id_localizacion,
                label: item.loc_nombre
              })))
              setTextDistrito({ value: dist.id_localizacion, label: dist.loc_nombre})
            }
          }

          setBitActividadEconomica(data.data.persona.es_asalariado);
          setGenero(data.data.persona.per_genero);

          if (data.data.persona.es_asalariado) {
            //setTextBitActividadEconomica('Asalariado');
            setValueIdActividadEconomica(1);
          }

          setBitDatosCedula(true);

          //Si la cedula es tipo nacional, se copia el valor al Numero de asegurado
          if (valueIdTipoCedula === 1) {
            setNumeroAsegurado(cedulaSinGuiones);
            setValorMinimoNumeroAsegurado(9);
          }
        }

        //si la respuesta del api es warning
        else if (data.respuesta_tipo === "warning") {
          if (
            data.respuesta_detalle ===
              "Ya posee un credito en proceso, favor consultar." ||
            data.respuesta_detalle === "La persona posee solicitudes activas"
          ) {
            Swal.fire({
              title: "Mensaje de información",
              text: data.respuesta_detalle,
              confirmButtonColor: "#001642",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setInputValueCedula("");
              }
            });
          } else if (data.respuesta_detalle === null) {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica(false);
            setNumeroAsegurado("");
            setValueIdActividadEconomica("");

            //Si la cedula es Nacional, se copia el valor al numero de asegurado
            if (valueIdTipoCedula === 1) {
              setNumeroAsegurado(cedulaSinGuiones);
              setValorMinimoNumeroAsegurado(9);
            }

            setGenero(1);

            Swal.fire(
              "Mensaje de información",
              "Datos personales no encontrados. Favor ingresar datos personales."
            );
          } else if (
            data.respuesta_detalle === "No se encontro información del cliente."
          ) {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica(data.data.persona.es_asalariado);
            //setBitActividadEconomica(false);
            setNumeroAsegurado("");
            //setValueIdActividadEconomica('');

            if (data.data.persona.es_asalariado) {
              //setTextBitActividadEconomica('Asalariado');
              setValueIdActividadEconomica(1); //123
            }

            //Si la cedula es Nacional, se copia el valor al numero de asegurado
            if (valueIdTipoCedula === 1) {
              setNumeroAsegurado(cedulaSinGuiones);
              setValorMinimoNumeroAsegurado(9);
            }

            setGenero(1);

            if (valueTipoIdentificacion === 1) {
              Swal.fire(
                "Mensaje de información",
                data.respuesta_detalle + " Favor ingresar datos personales."
              );
            } else {
              Swal.fire(
                "Mensaje de información",
                data.respuesta_detalle +
                  " Favor ingresar Número de Asegurado para realizar una nueva busqueda de sus datos."
              );
            }
          } else {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica("Carga Inicial");
            setNumeroAsegurado("");
            setValueIdActividadEconomica("");

            setGenero(1);

            Swal.fire(
              "Mensaje de información",
              //'Datos no encontrados. Favor revisar la identificación.'
              data.respuesta_detalle
            );
          }
        }
        //si la respuesta es error
        else if (data.respuesta_tipo === "error") {
          Swal.fire(
            "Mensaje de información",
            data.respuesta_detalle +
              " Limpiando formulario para nueva solicitud..."
          );

          setTimeout(() => {
            window.location.reload();
          }, 6000);
        }
      } else {
        setNombre("");
        setApellido1("");
        setApellido2("");
        setFechaNacimiento("");
        setBitActividadEconomica("Carga Inicial");
        setNumeroAsegurado("");
        setValueIdActividadEconomica("");
        setGenero(1);
        setErrorIdentificacion("Aún faltan digitos.");
      }
    } 
    
    
    //Fin del if Si no hay datos traidos por busqueda por numero de asegurado, permite buscar por cedula
  }; //final onblur cedula

  //Evento de cuando se quita el focus del input numero de asegurado
  //Se envia la cedula y el numero de asegurado al api para buscar datos personales
  const handleBlurNumeroAsegurado = async (event) => {
    //se procesa solo si es cedula residente, es decir, el id 2
    if (selectedOptionTipoIdentificacion.value === 1) {
      return;
    } else if (inputValueCedula.length < 9) {
      return;
    } else {
      setDataApiNumeroAsegurado(false);
      let newValue = event.target.value;

      // Limitar la longitud de dígitos segun tipo de id identificación
      let valueIdTipoCedula = selectedOptionTipoIdentificacion.value;
      let numberMin;
      let numerMax;

      //Cedula de Residencia
      if (valueIdTipoCedula === 2) {
        numberMin = 1;
        numerMax = 53;
        setValorMinimoIdentificacion(numberMin);
      }

      if (newValue.length > numerMax) {
        newValue = newValue.slice(0, numerMax);

        Swal.fire(
          "Verifica tus datos",
          "Número máximo de caracteres alcanzados."
        );
      }

      setErrorNumeroAsegurado("");

      if (event.target.value.length >= numberMin) {
        const data = await getDataCedulaNumeroAsegurado(
          inputValueCedula,
          newValue
        );

        //Si la respuesta del api es undefined, se limpian los datos
        if (data === undefined) {
          return;
        }
        //Si la respuesta del api es success, se setean los datos
        else if (data.respuesta_tipo === "success") {
          setDataApiNumeroAsegurado(true);

          //Nombre
          if (data.data.persona.per_nombre !== "") {
            setNombre(data.data.persona.per_nombre);
            setBitDataApiNombre(true);
          } else {
            setNombre("");
          }

          //Apellido 1
          if (data.data.persona.per_apellido1 !== "") {
            setApellido1(data.data.persona.per_apellido1);
            setBitDataApiApellido1(true);
          } else {
            setApellido1("");
          }

          //Apellido 2
          if (data.data.persona.per_apellido2 !== "") {
            setApellido2(data.data.persona.per_apellido2);
            setBitDataApiApellido2(true);
          } else {
            setApellido2("");
          }

          //Fecha de nacimiento
          if (data.data.persona.per_fecha_nac !== "") {
            setFechaNacimiento(data.data.persona.per_fecha_nac);
            setBitDataApiFechaNacimiento(true);
          } else {
            setFechaNacimiento("");
          }

          setBitActividadEconomica(data.data.persona.es_asalariado);
          setGenero(data.data.persona.per_genero);

          if (data.data.persona.es_asalariado) {
            //setTextBitActividadEconomica('Asalariado');
            setValueIdActividadEconomica(1); //123
          }

          setBitDatosCedula(true);
        }
        //si la respuesta del api es warning
        else if (data.respuesta_tipo === "warning") {
          if (
            data.respuesta_detalle ===
              "Ya posee un credito en proceso, favor consultar." ||
            data.respuesta_detalle === "La persona posee solicitudes activas"
          ) {
            Swal.fire({
              title: "Mensaje de información",
              text: data.respuesta_detalle,
              confirmButtonColor: "#001642",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                setInputValueCedula("");
                setNumeroAsegurado("");
              }
            });
          } else if (data.respuesta_detalle === null) {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica(false);
            //setNumeroAsegurado('');
            setValueIdActividadEconomica("");

            setGenero(1);

            Swal.fire(
              "Mensaje de información",
              "Datos personales no encontrados. Favor ingresar datos personales."
            );
          } else if (
            data.respuesta_detalle === "No se encontro información del cliente."
          ) {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica(data.data.persona.es_asalariado);

            if (data.data.persona.es_asalariado) {
              //setTextBitActividadEconomica('Asalariado');
              setValueIdActividadEconomica(1); //123
            }

            setGenero(1);

            Swal.fire(
              "Mensaje de información",
              data.respuesta_detalle + " Favor ingresar datos personales."
            );
          } else {
            setNombre("");
            setApellido1("");
            setApellido2("");
            setFechaNacimiento("");
            setBitActividadEconomica("Carga Inicial");
            //setNumeroAsegurado('');
            setValueIdActividadEconomica("");

            setGenero(1);

            Swal.fire(
              "Mensaje de información",
              //'Datos no encontrados. Favor revisar la identificación.'
              data.respuesta_detalle
            );
          }
        }
        //si la respuesta es error
        else if (data.respuesta_tipo === "error") {
          Swal.fire(
            "Mensaje de información",
            data.respuesta_detalle +
              " Limpiando formulario para nueva solicitud..."
          );

          setTimeout(() => {
            window.location.reload();
          }, 6000);
        }
      } else {
        setNombre("");
        setApellido1("");
        setApellido2("");
        setFechaNacimiento("");
        setBitActividadEconomica("Carga Inicial");
        //setNumeroAsegurado('');
        setValueIdActividadEconomica("");

        setErrorNumeroAsegurado("Aún faltan digitos.");
      }
    } // final if, sino es cedula nacional = 1
  }; //final onBlur Numero Asegurado

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeNombre = (event) => {
    setErrorNombre("");

    const value = event.target.value;

    // Limitar la longitud del valor a 30 caracteres y que sean solo letras
    if (value.length <= 30 && /^[a-zA-Z ]*$/.test(value)) {
      setNombre(event.currentTarget.value);
    }

    if (value.length > 30) {
      Swal.fire("Verifica tus datos", "Se permite un máximo de 30 caracteres.");
    }

    if (event.currentTarget.value.length === 0) {
      setBitDatosCedula(false);
    }
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeApellido1 = (event) => {
    setErrorApellido1("");

    const value = event.target.value;

    // Limitar la longitud del valor a 30 caracteres y que sean solo letras
    if (value.length <= 30 && /^[a-zA-Z]*$/.test(value)) {
      setApellido1(event.currentTarget.value);
    }

    if (value.length > 30) {
      Swal.fire("Verifica tus datos", "Se permite un máximo de 30 caracteres.");
    }

    if (event.currentTarget.value.length === 0) {
      setBitDatosCedula(false);
    }
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeApellido2 = (event) => {
    setErrorApellido2("");
    const value = event.target.value;

    // Limitar la longitud del valor a 30 caracteres y que sean solo letras
    if (value.length <= 30 && /^[a-zA-Z]*$/.test(value)) {
      setApellido2(event.currentTarget.value);
    }

    if (value.length > 30) {
      Swal.fire("Verifica tus datos", "Se permite un máximo de 30 caracteres.");
    }

    if (event.currentTarget.value.length === 0) {
      setBitDatosCedula(false);
    }
  };

  //cambio del valor de la fecha de nacimiento
  const handleChangeFechaNacimiento = (event) => {
    if (event === null) {
      Swal.fire(
        "Verifica tus datos",
        "Debes elegir una fecha en el caledario o digitar una, por ejemplo 01/12/1995"
      );
    } else {
      setErrorFechaNacimiento("");

      setFechaNacimiento(event);

      if (event.length === 0) {
        setBitDatosCedula(false);
      }
    }
  };

  //Función para validar el correo
  const isValidEmail = (correoEnRevision) => {
    if (correoEnRevision.length === 0) {
      return true;
    } else {
      const revision = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        correoEnRevision
      );

      return revision;
    }
  };

  //Función para validar si el dominio del correo es valido
  const isValidDomainEmail = (correoEnRevision) => {
    const domainArray = ["notiene", "noaplica", "nopuede", "noexiste"];

    // Expresión regular para validar el dominio
    const domainRegex = new RegExp(`@(${domainArray.join("|")})\\.`, "i");

    if (domainRegex.test(correoEnRevision)) {
      setErrorEmail("Dominio de correo invalido.");

      Swal.fire(
        "Verifica tus datos",
        "Debes digitar un dominio de correo valido. Por ejemplo @gmail.com, @hotmail.com, etc."
      );
    } else {
      setErrorEmail(null);
    }
  };

  //Función para validar el final del correo. Si el correo termina en ".no" o ".co"
  const emailEnd = async (correoEnRevision) => {
    if (/(.no|.co|.con)$/i.test(correoEnRevision)) {
      setErrorEmail('El correo no puede terminar en ".no", ".co" o "con"');

      await Swal.fire(
        "Verifica tus datos",
        'El correo es opcional, pero si ingresa uno, no puede terminar en ".no", ".co" o "con".'
      );

      return false;
    } else {
      setErrorEmail(null);

      return true;
    }
  };

  //Función para validar si el correo tiene datos antes del @ y si son validos
  const emailName = (correoEnRevision) => {
    if (correoEnRevision.length === 0) {
      return true;
    } else {
      if (!correoEnRevision.includes("@")) {
        setErrorEmail('El correo electrónico debe contener un "@"');
      } else if (correoEnRevision.indexOf("@") === 0) {
        setErrorEmail('El correo electrónico no puede comenzar con "@"');
      } else if (
        correoEnRevision.indexOf("@") ===
        correoEnRevision.length - 1
      ) {
        setErrorEmail('El correo electrónico no puede terminar con "@"');
      } else if (correoEnRevision.includes("noexiste")) {
        setErrorEmail('El correo no puede contener "noexiste"');
      } else if (correoEnRevision.includes("nopuede")) {
        setErrorEmail('El correo no puede contener "nopuede"');
      } else if (correoEnRevision.includes("notiene")) {
        setErrorEmail('El correo no puede contener "notiene"');
      }
    }
  };

  //evento para cambiar el valor del correo
  const handleChangeEmail = (event) => {
    const correoEnRevision = event.target.value.trim();

    setErrorEmail("");

    if (!isValidEmail(correoEnRevision)) {
      setErrorEmail("Correo invalido.");
    } else {
      isValidDomainEmail(correoEnRevision);

      //función para revisar el correo antes del @
      emailName(correoEnRevision);
    }

    setEmail(correoEnRevision);
  };

  //evento para cambiar el valor del numero de asegurado
  const handleChangeNumeroAsegurado = (event) => {
    //se procesa solo si es cedula residente, es decir, el id 2
    if (selectedOptionTipoIdentificacion.value === 1) {
      return;
    } else if (inputValueCedula.length < 9) {
      Swal.fire(
        "Mensaje de información",
        "Debes digitar primero una identificación valida para buscar datos personales."
      );

      return;
    } else {
      //se eliminan los espacios
      const newValueNumeroAsegurado = event.currentTarget.value.trim();

      // si es Residente
      if (selectedOptionTipoIdentificacion.value === 2) {
        let numberMin = 1;
        let numerMax = 53;

        setValorMinimoNumeroAsegurado(numberMin);

        //valor maximo de digitos
        if (newValueNumeroAsegurado.length < numberMin) {
          setErrorNumeroAsegurado("Aún faltan digitos");
          setNumeroAsegurado(newValueNumeroAsegurado);
        } else if (
          newValueNumeroAsegurado.length >= numberMin &&
          newValueNumeroAsegurado.length <= numerMax
        ) {
          setErrorNumeroAsegurado("");
          setNumeroAsegurado(newValueNumeroAsegurado);
        } else if (newValueNumeroAsegurado.length > numerMax) {
          Swal.fire(
            "Mensaje de información",
            "No puedes digitar más de " + numerMax + " caracteres."
          );
        }
      }
    }
  };

  //Función para enviar valor del state
  const handleClickButtonEnviarPresolicitud = async () => {
    let errorEnDatosParaEnviar = false;

    let telefonoSinGuiones = "";
    let correoRevisado;

    //debugger

    if (sucursal === "") {
      setErrorSucursal("Requerida");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (
      inputValueCedula.trim().length < valorMinimoIdentificacion ||
      errorIdentificacion !== ""
    ) {
      setErrorIdentificacion("Requerida.");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (numeroAsegurado.trim().length < valorMinimoNumeroAsegurado) {
      setErrorNumeroAsegurado("Requerido");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (nombre.trim().length <= 0) {
      setErrorNombre("Requerido");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (apellido1.trim().length <= 0) {
      setErrorApellido1("Requerido");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (apellido2.trim().length <= 0) {
      setErrorApellido2("Requerido");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (fechaNacimiento === "") {
      setErrorFechaNacimiento("Requerida");
      errorEnDatosParaEnviar = true;
    }

    //debugger
    if (email.trim().length <= 0) {
      correoRevisado = "";
    } else if (errorEmail !== null) {
      errorEnDatosParaEnviar = true;
    } else {
      const resCorreo = await emailEnd(email);

      if (resCorreo === true) {
        correoRevisado = email;
      } else {
        errorEnDatosParaEnviar = true;
      }
    }

    //debugger
    //Telefono minimo de 8 digitos
    if (telefono.trim().length <= 7) {
      setErrorTelefono("Requerido");
      errorEnDatosParaEnviar = true;
    } else {
      telefonoSinGuiones = telefono.replace(/-/g, "");
    }

    //debugger
    if (valueIdActividadEconomica === "") {
      setErrorActividadEconomica("Seleccione una opción");
      //setErrorTipoCredito('Se debe de elegir una opción')
      errorEnDatosParaEnviar = true;

      if (textTipoCredito === "") {
        setErrorTipoCredito("Seleccione una opción");
      }
    }

    //debugger
     if(textProvincia.length <= 0){

       setErrorProvincia('Requerida');
       errorEnDatosParaEnviar = true;

     }

    // //debugger
     if(textCanton.length <= 0){

       setErrorCanton('Requerido');
       errorEnDatosParaEnviar = true;

     }


     //debugger
     if(textDistrito.length <= 0){

       setErrorDistrito('Requerido');
       errorEnDatosParaEnviar = true;

     }

    // //debugger
    // if(textBarrio.length <= 0){

    //   setErrorBarrio('Requerido');
    //   errorEnDatosParaEnviar = true;

    // }

    // //debugger
    // if(idPoblado === ''){

    //   setErrorPoblado('Requerido');
    //   errorEnDatosParaEnviar = true;

    // }

    //Funcion que entrega la localizacion posible llenada en el formulario
    function entregarLocalizacion(NivelForce = 0) {
      if (NivelForce === 5 || idPoblado.value) { return idPoblado.value }
      if (NivelForce === 4 || textBarrio.value)  { return textBarrio.value; }
      if (NivelForce === 3 || textDistrito.value){ return textDistrito.value; }
      if (NivelForce === 2 || textCanton.value)    { return textCanton.value; }
      if (NivelForce === 1 || textProvincia.value) {  return textProvincia.value; }
      else { console.log('Localidad  Vacia'); return ''; }
  }


    //debugger
    if (!errorEnDatosParaEnviar) {
      //Se muestra modal con spinner
      setLgShowRespuestaPresolicitud(true);
      console.log({
        codigo_sucursal: sucursal,
        cedula: inputValueCedula,
        nombre: nombre.trim(),
        apellido1: apellido1,
        apellido2: apellido2,
        nacimiento: fechaNacimiento,
        id_perdoc: valueTipoIdentificacion,
        correo: correoRevisado,
        telefono: parseInt(telefonoSinGuiones),
        idActividadEconomica: valueIdActividadEconomica,
        idLocalizacion: 0,
        numeroAsegurado: numeroAsegurado,
        per_genero: genero,
      });

      //Se envian datos al API
      await sendDataPreSolicitud({
        codigo_sucursal: sucursal,
        cedula: inputValueCedula,
        nombre: nombre.trim(),
        apellido1: apellido1,
        apellido2: apellido2,
        nacimiento: fechaNacimiento,
        id_perdoc: valueTipoIdentificacion,
        correo: correoRevisado,
        telefono: parseInt(telefonoSinGuiones),
        idActividadEconomica: valueIdActividadEconomica,
        idLocalizacion: entregarLocalizacion(),
        numeroAsegurado: numeroAsegurado,
        per_genero: genero,
      });


  
      //se deja de mostrar modal con spinner
      setLgShowRespuestaPresolicitud(false);
    } else {
      await Swal.fire(
        "Verifica tus datos",
        "Debes completar todos los datos requeridos."
      );

      //se deja de mostrar modal con spinner
      setLgShowRespuestaPresolicitud(false);
    }
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeProvincias = (event) => {
    setErrorProvincia("");

    //Se buscan los cantones que tienen la provincia elegida
    const valorCanton = canton.filter(function (item) {
      return item.loc_nodo_padre === event.value;
    });

    //Se setean conforme lo pide el componente Select
    const prepararDatosSelect = valorCanton.map((item) => ({
      value: item.id_localizacion,
      label: item.loc_nombre,
    }));

    //se setea el valor para la validación
    setTextProvincia(event);

    //se limpia el texto que se muestra en el select
    setTextCanton("");
    setTextDistrito("");
    setTextBarrio("");
    setTextPoblado("");

    //se guarda en el state la lista de cantones
    setListCanton(prepararDatosSelect);

    setListDistrito([]);
    setListBarrio([]);
    setListPoblado([]);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeCanton = (event) => {
    setErrorCanton("");

    //Se buscan los cantones que tienen la provincia elegida
    const valorDistrito = distrito.filter(function (item) {
      return item.loc_nodo_padre === event.value;
    });

    //Se setean conforme lo pide el componente Select
    const prepararDatosSelect = valorDistrito.map((item) => ({
      value: item.id_localizacion,
      label: item.loc_nombre,
    }));

    //se muestra el texto del valor elegido
    setTextCanton(event);

    //se limpia el texto que se muestra en el select
    setTextDistrito("");
    setTextBarrio("");
    setTextPoblado("");

    //se guarda en el state la lista de cantones
    setListDistrito(prepararDatosSelect);

    setListBarrio([]);
    setListPoblado([]);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeDistrito = (event) => {
    setErrorDistrito("");

    //Se buscan los cantones que tienen la provincia elegida
    const valorBarrio = barrio.filter(function (item) {
      return item.loc_nodo_padre === event.value;
    });

    //Se setean conforme lo pide el componente Select
    const prepararDatosSelect = valorBarrio.map((item) => ({
      value: item.id_localizacion,
      label: item.loc_nombre,
    }));

    //se muestra el texto del valor elegido
    setTextDistrito(event);

    //se settea el valor del distrito
    setIdPoblado(event.value);

    //se limpia el texto que se muestra en el select
    setTextBarrio("");
    setTextPoblado("");

    //se guarda en el state la lista de cantones
    setListBarrio(prepararDatosSelect);

    setListPoblado([]);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeBarrio = (event) => {
    setErrorBarrio("");

    //Se buscan los cantones que tienen la provincia elegida
    const valorPoblado = poblado.filter(function (item) {
      return item.loc_nodo_padre === event.value;
    });

    //Se setean conforme lo pide el componente Select
    const prepararDatosSelect = valorPoblado.map((item) => ({
      value: item.id_localizacion,
      label: item.loc_nombre,
    }));

    //se muestra el texto del valor elegido
    setTextBarrio(event);

    //se limpia el texto que se muestra en el select
    setTextPoblado("");

    //se guarda en el state la lista de cantones
    setListPoblado(prepararDatosSelect);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangePoblado = (event) => {
    setErrorPoblado("");

    setTextPoblado(event);
    setIdPoblado(event.value);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeTipoCredito = (event) => {
    if (event.value === "opcionEscogerActividadEconomica") {
      setErrorTipoCredito("");
      setErrorActividadEconomica("");
      setBitActividadEconomica("No Asalariado - elegir actividad economica");
      setTextTipoCredito(event);
    } else if (event.value === "opcionAmaDeCasa") {
      setErrorTipoCredito("");
      setErrorActividadEconomica("");
      setBitActividadEconomica(false);
      setTextTipoCredito(event);
      setValueIdActividadEconomica(130);
    }
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeActividadEconomica = (event) => {
    setErrorActividadEconomica("");
    setValueIdActividadEconomica(event.value);
  };

  //Función para manejar datos ingresados al usuario y que se guarden en state
  const handleChangeTipoIdentificacion = (selectedOption) => {
    setValueTipoIdentificacion(selectedOption.value);

    if (selectedOption.value === 1) {
      setValorMinimoIdentificacion(9);
    }
    //Cedula de Residencia
    else if (selectedOption.value === 2) {
      setValorMinimoIdentificacion(9);
    }
    //Pasaporte nacional o extranjero
    else if (selectedOption.value === 3 || selectedOption.value === 4) {
      setValorMinimoIdentificacion(9);
    }

    setSelectedOptionTipoIdentificacion(selectedOption);

    //Al elegir un nuevo tipo de identificación se limpian los campos
    setInputValueCedula("");

    setNombre("");
    setApellido1("");
    setApellido2("");
    setFechaNacimiento("");
    setEmail("");
    setTelefono("");
    setValueIdActividadEconomica("");
    setTextProvincia("");
    setTextCanton("");
    setTextDistrito("");
    setTextBarrio("");
    setTextPoblado("");
    setNumeroAsegurado("");
    setGenero("");
    setTextTipoCredito("");
    //setTextBitActividadEconomica('');

    setErrorIdentificacion("");
    setErrorNombre("");
    setErrorApellido1("");
    setErrorApellido2("");
    setErrorFechaNacimiento("");
    setErrorEmail("");
    setErrorTelefono("");
    setErrorActividadEconomica("");
    setErrorTipoCredito("");
    setErrorProvincia("");
    setErrorCanton("");
    setErrorDistrito("");
    setErrorBarrio("");
    setErrorPoblado("");
    setErrorNumeroAsegurado("");

    setBitDatosCedula(false);
  };

  //Función para obtener los datos del API al iniciar la pagina
  const getListFirstDataSelects = async () => {
    localStorage.setItem(
      "data-mc-c",
      "hamc7d8JDDm1dgg6s35tap4xafNAd4s5G45Ddgat554SDV5d5d5a9a3sS"
    );

    try {
      const resultListFirstDataSelects = await listFirstDataSelects();

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelectSucursal =
        resultListFirstDataSelects.data.sucursales.map((item) => ({
          value: item.suc_ekono,
          label: item.suc_nombre,
        }));

      setListSucursal(prepararDatosSelectSucursal);

      //se guardan localizaciones para usar en la solicitud en localstorage

      // Filtrar y guardar el array que contiene las provincias
      setProvincias(resultListFirstDataSelects.data.localizaciones[0]);
      localStorage.setItem(
        "lista-provincias-solicitud-mc",
        JSON.stringify(resultListFirstDataSelects.data.localizaciones[0])
      );

      // Filtrar y guardar el array que contiene las canton
      setCanton(resultListFirstDataSelects.data.localizaciones[1]);
      localStorage.setItem(
        "lista-canton-solicitud-mc",
        JSON.stringify(resultListFirstDataSelects.data.localizaciones[1])
      );

      // Filtrar y guardar el array que contiene las distrito
      setDistrito(resultListFirstDataSelects.data.localizaciones[2]);
      localStorage.setItem(
        "lista-distrito-solicitud-mc",
        JSON.stringify(resultListFirstDataSelects.data.localizaciones[2])
      );

      // // Filtrar y guardar el array que contiene las barrio
      // setBarrio(resultListFirstDataSelects.data.localizaciones[3]);

      // // Filtrar y guardar el array que contiene las poblado
      // setPoblado(resultListFirstDataSelects.data.localizaciones[4]);

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelectActividadesEcononicas =
        resultListFirstDataSelects.data.actividades_economicas.map((item) => ({
          value: item.id_actividad_economica,
          label: item.nombre_act_eco,
        }));

      //Filtrar y guardar el array que contiene las actividades económicas
      setListActividadEconomica(prepararDatosSelectActividadesEcononicas);

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelectTipoIdentificacion =
        resultListFirstDataSelects.data.tipos_de_identificacion.map((item) => ({
          value: item.id_perdoc,
          label: item.perdoc_nombre,
        }));

      //Filtrar y guardar el array que contiene las actividades económicas
      setListTipoIdentificacion(prepararDatosSelectTipoIdentificacion);

      //setear valor por defecto en el select
      setSelectedOptionTipoIdentificacion(
        prepararDatosSelectTipoIdentificacion[0]
      );

      let confirmacionBitSucursal;

      //Setea true o false si en el link hay un codigo sucursal
      if (codigoSucursal === undefined) {
        confirmacionBitSucursal = false;
      } else {
        //Si viene codigo id sucursal, verifica si el codigo existe de la lista de sucursales enviada

        confirmacionBitSucursal = true;

        const idSucursalValidado = contieneIdSucursal(
          prepararDatosSelectSucursal,
          codigoSucursal
        );

        //Si existe
        if (idSucursalValidado === true) {
          confirmacionBitSucursal = true;

          //Se comentan las siguientes lineas ya que si se encuentra la sucursal por codigo QR no se debe mostrar ningun input con la info de dicha sucursal encontrada

          //const resultLabel = obtenerLabelPorValor(prepararDatosSelectSucursal, codigoSucursal);

          //setNombreSucursal(resultLabel);

          setSucursal(codigoSucursal);
        }
        //no existe
        else {
          confirmacionBitSucursal = false;
        }
      }

      setBitSucursal(confirmacionBitSucursal);
    } catch (error) {
      Swal.fire(
        "Verifica tus datos",
        "Lo sentimos. No se pudieron cargar los datos iniciales del formulario."
      );
    }
  };

  //Función para consumir el API de buscar datos personales.
  //Se envia el valor de la cedula para el parametro cedula y numero de asegurado
  //Para que realice primero la busqueda solo con ese dato
  const getDataCedula = async (cedula) => {
    //Se muestra el modal con el spinner
    setLgShow(true);

    try {
      //Función de consulta para traer datos de la cedula digitada
      const resultGetDataCedula = await dataCedula(cedula);

      //Se quita el modal con el spinner
      lgHandleClose();

      return resultGetDataCedula;
    } catch (error) {
      lgHandleClose();

      Swal.fire(
        "Verifica tus datos",
        "Lo sentimos. No se pudieron cargar los datos de la identificación."
      );
    }
  };

  //Función para consumir el API de buscar datos personales.
  //Para los parametros cedula y numero de asegurado, se envian los valores digitados en cada campo
  const getDataCedulaNumeroAsegurado = async (cedula, numeroAsegurado) => {
    //Se muestra el modal con el spinner
    setLgShow(true);

    try {
      //Función de consulta para traer datos de la cedula y el numero de asegurado
      const resultGetDataCedulaNumeroAsegurado =
        await dataCedulaNumeroAsegurado(cedula, numeroAsegurado);

      //Se quita el modal con el spinner
      lgHandleClose();

      return resultGetDataCedulaNumeroAsegurado;
    } catch (error) {
      lgHandleClose();

      Swal.fire(
        "Verifica tus datos",
        "Lo sentimos. No se pudieron cargar los datos de la identificación y el número de asegurado."
      );
    }
  };

  //Función para identificar si el id sucursal encriptado recibido en el link
  //se encuentra en la lista de sucursales recibidas del API
  const contieneIdSucursal = (jsonArray, valorBuscado) => {
    return jsonArray.some((elemento) => elemento.value === valorBuscado);
  };

  //Se comentan las siguientes lineas ya que si se encuentra la sucursal por codigo QR no se debe mostrar ningun input con la info de dicha sucursal encontrada

  //   const obtenerLabelPorValor = (jsonArray, valorBuscado) => {
  //    const elementoEncontrado = jsonArray.find(elemento => elemento.value === valorBuscado);
  //    return elementoEncontrado ? elementoEncontrado.label : null;
  // }

  //Hook que se ejecuta al cargar la pagina
  useEffect(() => {
    //Función que trae los datos para llenar los selects
    getListFirstDataSelects();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Se setean conforme lo pide el componente Select
  const listProvincias = provincias.map((item) => ({
    value: item.id_localizacion,
    label: item.loc_nombre,
  }));

  //Función para formatear la fecha cuando viene del API
  const formatDate = (valueDateAPI) => {
    const valueDate = new Date(valueDateAPI);
    const date = new Date(valueDate);
    date.setDate(valueDate.getDate() + 1);
    // Formatear la fecha como "dd/MM/yyyy"
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  //Validación para mostrar el spinner
  if (estadoCargando === true) {
    return <SpinnerCarga title="Cargando formulario..." />;
  }

  //Style para los Select
  const customStylesSelect = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
  };

  //Se renderiza el siguiente JSX
  return (
    <div className="main-container">
      {/* Modal para presentar el spinner de carga de datos personales */}
      {lgShow === true ? (
        <Modal show={lgShow} onHide={lgHandleClose} size="lg" centered>
          <Modal.Body>
            <div className="mb-4 mt-4">
              <SpinnerCarga title="Cargando persona..." />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {/* Modal para presentar spinner al darle clic al enviar Presolicitud */}
      {lgShowRespuestaPresolicitud === true ? (
        <Modal
          show={lgShowRespuestaPresolicitud}
          onHide={lgHandleCloseRespuestaPresolicitud}
          size="lg"
          centered
        >
          <Modal.Body>
            <div className="mb-4 mt-4">
              <SpinnerCarga title="Procesando solicitud. Un momento por favor..." />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {/*Header - Logo*/}
      <HeaderLogo src={logo} alt="Logo Credie" />
      {/*Header - IMG */}
      <HeaderImagen
        banner={banner}
        bannerMobile={bannerMobile}
        alt={"Imagen CrediE"}
      />
      <div className="form-container">
        {/* Title */}
        <h4 className="text-center">Presolicitud</h4>

        <div className="row mb-3">
          <div className="col-md-12 mb-3">
            {/* Si se recibe el id sucursal por el link */}
            {/* Se mostraba el dato de la sucursal pero se pidió por ahora no mostrar */}
            {/* Si no viene, se muestra el input para que se elija una sucursal */}
            {bitSucursal === true ? (
              <>
                {/* Se comenta lo siguiente por si se requiere el nombre de la sucursal obtenida por QR */}
                {/*
                          <p className="mb-3"><label>Sucursal:  {errorSucursal && <spam style={{color: 'red'}}>{errorSucursal}</spam>}</label></p>
                              <input
                              className="w-100 input-form"
                              type="text"
                              value={nombreSucursal}
                              readOnly={true}
                          /> */}
              </>
            ) : (
              <>
                <p className="mb-3">
                  <label htmlFor="sucursal-select">
                    Sucursal:{" "}
                    {errorSucursal && (
                      <spam style={{ color: "red" }}>{errorSucursal}</spam>
                    )}
                  </label>
                </p>
                <Select
                  name="sucursal-select"
                  options={listSucursal}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={handleChangeSucursal}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                />
              </>
            )}
          </div>
        </div>

        {/* Title Data */}
        <h4 className="text-center mb-4">Información del Cliente</h4>

        {/*Row 1 */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">Tipo de identificación:</p>
            <Select
              name="tipoIdentificacion-select"
              options={listTipoIdentificacion}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeTipoIdentificacion}
              placeholder="Buscar"
              value={selectedOptionTipoIdentificacion}
              styles={customStylesSelect}
            />
          </div>
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Identificación:{" "}
                {errorIdentificacion && (
                  <spam style={{ color: "red" }}>{errorIdentificacion}</spam>
                )}
              </label>
            </p>

            {/* Si es cedula nacional, se muestra el primer input y sino el segundo para Residentes */}
            {/* Ambos inputs contienen mascara al digitar */}
            {valueTipoIdentificacion === 1 ? (
              <>
                <MaskedInput
                  mask={MaskCedulaNacional}
                  guide={false} // Indica que no muestre los guiones automáticamente
                  id="cedulaNacional"
                  name="cedulaNacional"
                  placeholder="Ej: 1-0123-0123"
                  className="w-100 input-form"
                  value={inputValueCedula}
                  onChange={handleChangeCedula}
                  onKeyDown={handleKeyDownCedula}
                  onBlur={handleBlurCedula}
                />
              </>
            ) : (
              <>
                <InputMask
                  mask="***************"
                  maskChar={null}
                  id="cedulaExtranjero"
                  name="cedulaExtranjero"
                  className="w-100 input-form"
                  placeholder="_______________"
                  value={inputValueCedula}
                  onChange={handleChangeCedula}
                  onKeyDown={handleKeyDownCedula}
                  onBlur={handleBlurCedula}
                />
              </>
            )}
          </div>
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                N° de asegurado:{" "}
                {errorNumeroAsegurado && (
                  <spam style={{ color: "red" }}>{errorNumeroAsegurado}</spam>
                )}{" "}
              </label>
            </p>

            {/* Numero de asegurado, contiene mascara al digitar */}
            <input
              className="w-100 input-form"
              name="numeroAsegurado"
              type="text"
              value={numeroAsegurado}
              onChange={handleChangeNumeroAsegurado}
              onBlur={handleBlurNumeroAsegurado}
            />
          </div>
        </div>

        {/*Row 2 */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Nombre:{" "}
                {errorNombre && (
                  <spam style={{ color: "red" }}>{errorNombre}</spam>
                )}
              </label>
            </p>

            {/* Si se recibieron datos personales del API y el nombre no está vacio, se muestra el dato y sino se habilita para ingresar el dato */}
            {bitDatosCedula && bitDataApiNombre ? (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={nombre}
                  onChange={handleChangeNombre}
                  readOnly={bitDatosCedula}
                  name="nombreCliente"
                />
              </>
            ) : (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={nombre}
                  onChange={handleChangeNombre}
                  name="nombreCliente"
                />
              </>
            )}
          </div>
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Primer Apellido:{" "}
                {errorApellido1 && (
                  <spam style={{ color: "red" }}>{errorApellido1}</spam>
                )}
              </label>
            </p>

            {/* Si se recibieron datos personales del API y el apellido1 no está vacio, se muestra el dato y sino se habilita para ingresar el dato */}
            {bitDatosCedula && bitDataApiApellido1 ? (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={apellido1}
                  onChange={handleChangeApellido1}
                  readOnly={bitDatosCedula}
                  name="apellido1Cliente"
                />
              </>
            ) : (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={apellido1}
                  onChange={handleChangeApellido1}
                  name="apellido1Cliente"
                />
              </>
            )}
          </div>
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Segundo Apellido:{" "}
                {errorApellido2 && (
                  <spam style={{ color: "red" }}>{errorApellido2}</spam>
                )}
              </label>
            </p>

            {/* Si se recibieron datos personales del API y el apellido2 no está vacio, se muestra el dato y sino se habilita para ingresar el dato */}
            {bitDatosCedula && bitDataApiApellido2 ? (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={apellido2}
                  onChange={handleChangeApellido2}
                  readOnly={bitDatosCedula}
                  name="apellido2Cliente"
                />
              </>
            ) : (
              <>
                <input
                  className="w-100 input-form"
                  type="text"
                  value={apellido2}
                  onChange={handleChangeApellido2}
                  name="apellido2Cliente"
                />
              </>
            )}
          </div>
        </div>

        {/*Row 3 */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            {/* Si se recibieron datos personales del API y la fecha de nacimiento no está vacio, se muestra el dato y sino se habilita para ingresar el dato */}
            {bitDatosCedula === true && bitDataApiFechaNacimiento ? (
              <>
                <p>
                  <label>
                    Fecha de nacimiento:{" "}
                    {errorFechaNacimiento && (
                      <spam style={{ color: "red" }}>
                        {errorFechaNacimiento}
                      </spam>
                    )}
                  </label>
                </p>
                <input
                  type="text"
                  className="w-100 input-form"
                  value={formatDate(fechaNacimiento)}
                  readOnly={bitDatosCedula}
                  name="fechaNacimientoCliente"
                />
              </>
            ) : (
              <div className="w-100">
                <p className="mb-3">
                  <label>
                    Fecha de nacimiento:{" "}
                    {errorFechaNacimiento && (
                      <spam style={{ color: "red" }}>
                        {errorFechaNacimiento}
                      </spam>
                    )}
                  </label>
                </p>
                {/* Formato  01/12/1995*/}
                <DatePicker
                  className="w-100 input-form"
                  selected={fechaNacimiento}
                  onChange={handleChangeFechaNacimiento}
                  dateFormat="dd/MM/yyyy"
                  value={fechaNacimiento}
                  wrapperClassName="datePickerFechaNacimiento"
                  placeholderText="Ej: 01/12/1995"
                  name="fechaNacimientoCliente"
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p>
              <label>
                Teléfono:{" "}
                {errorTelefono && (
                  <spam style={{ color: "red" }}>{errorTelefono}</spam>
                )}
              </label>
            </p>

            {/* Input para el telefono, contiene mascara */}
            <MaskedInput
              mask={MaskTelefono}
              guide={false} // Indica que no muestre automáticamente los guiones
              id="telefono"
              name="telefono"
              placeholder="____-____"
              value={telefono}
              onChange={handleTelefonoChange}
              className="input-form"
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p>
              <label>
                Correo electrónico:{" "}
                {errorEmail && (
                  <spam style={{ color: "red" }}>{errorEmail}</spam>
                )}
              </label>{" "}
            </p>

            {/* Correo electronico, es opcional. Se permita estar en blanco */}
            {/* Pero si se digita un correo debe cumplir con las validaciones */}
            <input
              className="w-100 input-form"
              type="text"
              value={email}
              onChange={handleChangeEmail}
              name="emailCliente"
            />
          </div>
        </div>

        {/*Row 4 */}
        <div id="localizacion0" className="row mb-3" >
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              Provincia:{" "}
              {errorProvincia && (
                <spam style={{ color: "red" }}>{errorProvincia}</spam>
              )}
            </p>

            {/* Provincia, datos recibidos del API */}
            <Select
              name="provincias-select"
              options={listProvincias}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeProvincias}
              placeholder="Buscar"
              styles={customStylesSelect}
              value={textProvincia}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              Cantón:{" "}
              {errorCanton && (
                <spam style={{ color: "red" }}>{errorCanton}</spam>
              )}
            </p>

            {/*Canton, se cargan datos una vez elegido el canton */}
            <Select
              name="canton-select"
              options={listCanton}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeCanton}
              value={textCanton}
              placeholder="Buscar"
              styles={customStylesSelect}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              Distrito: {errorDistrito}{" "}
              {errorDistrito && (
                <spam style={{ color: "red" }}>{errorDistrito}</spam>
              )}
            </p>

            {/*Distrito, se cargan los datos, una vez elegido el canton */}
            <Select
              name="distrito-select"
              options={listDistrito}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeDistrito}
              value={textDistrito}
              placeholder="Buscar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        {/*Row 5 */}
        <div id="localizacion1" className="row mb-3" hidden>
          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              Barrio:{" "}
              {errorBarrio && (
                <spam style={{ color: "red" }}>{errorBarrio}</spam>
              )}
            </p>

            {/*Barrio, datos se cargan, hasta que se elija el distrito */}
            <Select
              name="barrio-select"
              options={listBarrio}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeBarrio}
              value={textBarrio}
              placeholder="Buscar"
              styles={customStylesSelect}
            />
          </div>

          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              Poblado:{" "}
              {errorPoblado && (
                <spam style={{ color: "red" }}>{errorPoblado}</spam>
              )}
            </p>

            {/* Poblado, salen las opciones hasta que se elija el Barrio */}
            {/* El id Poblado es el unico de todas las localizaciones, que se envia al API */}
            <Select
              name="poblado-select"
              options={listPoblado}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangePoblado}
              value={textPoblado}
              placeholder="Buscar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        {/*Row 6 */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-6 mb-3">
            {/* Dato de carga al iniciar de la pagina, se solicita ahora dejar en blanco */}
            {bitActividadEconomica === "Carga Inicial" ? <></> : <></>}

            {/* Dato de cuando es tipo Asalariado */}
            {bitActividadEconomica === true ? (
              <>
                <p>
                  <label>Tipo de ingreso:</label>
                </p>
                Asalariado
              </>
            ) : (
              <></>
            )}

            {/* Dato de cuando es no tipo Asalariado */}
            {bitActividadEconomica === false ? (
              <>
                <p>
                  <label>
                    Tipo de ingreso:{" "}
                    {errorTipoCredito && (
                      <spam style={{ color: "red" }}>{errorTipoCredito}</spam>
                    )}
                  </label>
                </p>

                {/* Tipo de ingreso,  */}
                {/* Si se elije Ama de casa, se predefine el id de ama de casa para enviar al API*/}
                {/* Si se elije la opción Escoger actividad economica, se muestra Select Tipo de Actividad Economica */}
                <Select
                  name="tipoCredito-select"
                  options={optionNoAsalariado}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={handleChangeTipoCredito}
                  value={textTipoCredito}
                  placeholder="Elegir un tipo"
                  styles={customStylesSelect}
                />
              </>
            ) : (
              <></>
            )}

            {/* Dato de cuando no es tipo Asalariado */}
            {bitActividadEconomica ===
            "No Asalariado - elegir actividad economica" ? (
              <>
                <p>
                  <label>
                    Tipo de ingreso:{" "}
                    {errorTipoCredito && (
                      <spam style={{ color: "red" }}>{errorTipoCredito}</spam>
                    )}
                  </label>
                </p>

                {/* Tipo de ingreso por saber que es no asalariado por el api */}
                {/* Si se elije Ama de casa, se predefine el id de ama de casa para enviar al API*/}
                {/* Si se elije la opción Escoger actividad economica, se muestra Select Tipo de Actividad Economica */}
                <Select
                  name="tipoCredito-select"
                  options={optionNoAsalariado}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={handleChangeTipoCredito}
                  value={textTipoCredito}
                  placeholder="Elegir un tipo"
                  styles={customStylesSelect}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="col-md-12 col-lg-6 mb-3">
            {/* No se muestra actividad economica porque se espera datos de cedula ingresada, se solicita dejar así en blanco */}
            {bitActividadEconomica === "Carga Inicial" ? <></> : <></>}

            {/* No se muestra actividad economica porque es Asalariado, se solicita dejar así en blanco  */}
            {bitActividadEconomica === true ? <></> : <></>}

            {/* Mostrar Select actividad economica al elegir selección tipo de Actividad Economica */}
            {bitActividadEconomica ===
            "No Asalariado - elegir actividad economica" ? (
              <>
                <p className="mb-3">
                  Actividad Económica:{" "}
                  {errorActividadEconomica && (
                    <spam style={{ color: "red" }}>
                      {errorActividadEconomica}
                    </spam>
                  )}
                </p>

                {/* Actividad economica a elegir */}
                <Select
                  name="actividadEconomica-select"
                  options={listActividadEconomica}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={handleChangeActividadEconomica}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          {/* Boton Enviar */}
          <div className="text-center mt-3">
            <button
              onClick={handleClickButtonEnviarPresolicitud}
              className=" mb-4 button-enviar"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>{" "}
      {/* Fin form-container */}
      {/* Footer */}
      <Footer src={logo} alt={"Logo Credie"} />
    </div> // Fin main-container
  ); // Fin return
}
