import React from 'react'

export default function HeaderLogo(params) {
  return (
    <>

        <div className='header-logo navbar-border ml-0 text-center'>
                <img
                    className='img-responsive text-center header-img-logo'
                    src={params.src}
                    alt={params.alt} 
                />
        </div>

        
      
    </>
  )
}
