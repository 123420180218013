import React from "react";
import { Button, Spinner } from "react-bootstrap";
import '../styles/css/components/_spinnerLogin.css';

export default function SpinnerCarga(params) {

  return (

    //  Spinner de carga
    <div className="contenedorSpinnerLogin">
      <div className="SpinnerLogin">
        <Button className="text-center colors-border" variant="btn" disabled>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="colors"
          />
          <span className="colors">{ "   " + params.title }</span>
        </Button>
      </div>
    </div>

)

};
