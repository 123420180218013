//React
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Librerias
import Select from "react-select";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";

//Funciones Hooks
import { useSolicitudStore } from "../../hooks";

//Components
import HeaderLogo from "../../sharedComponents/HeaderLogo";
import HeaderImagen from "../../sharedComponents/HeaderImagen";
import Footer from "../../sharedComponents/Footer";

//Spinner component
import { useAuthStore } from "../../hooks";
import SpinnerCarga from "../../sharedComponents/SpinnerCarga";

//css
import "../../styles/css/views/containerForm.css";
import "../../styles/css/components/_header.css";
import "../../styles/css/components/_footer.css";

//imagenes
import banner from "../../styles/assets/Banner-Aprobado-Flujo-Credito-Credie-2.png";
import bannerMobile from "../../styles/assets/Banner-Aprobado-Flujo-Credito-Credie-Movil-2.png";
import logo from "../../styles/assets/logo-credie.png";

export default function Solicitud() {
  const navigate = useNavigate();

  const { estadoCargando } = useAuthStore();

  const { listFirstDataSolicitud, sendDataSolicitud } = useSolicitudStore();

  //states
  const [dataApiAmaDeCasa, setDataApiAmaDeCasa] = useState(false);

  //En caso de que ama de casa sea true, se guardan los siguientes states
  const [amaDeCasaLugar, setAmaDeCasaLugar] = useState("");

  const [amaDeCasaTelefono, setAmaDeCasaTelefono] = useState("");

  const [amaDeCasaTextPuesto, setAmaDeCasaTextPuesto] = useState("");
  const [amaDeCasaIdPuesto, setAmaDeCasaIdPuesto] = useState("");

  const [amaDeCasaTextProvincia, setAmaDeCasaTextProvincia] = useState("");
  const [amaDeCasaIdProvincia, setAmaDeCasaIdProvincia] = useState("");

  const [amaDeCasaTextCanton, setAmaDeCasaTextCanton] = useState("");
  const [amaDeCasaIdCanton, setAmaDeCasaIdCanton] = useState("");

  const [amaDeCasaTextDistrito, setAmaDeCasaTextDistrito] = useState("");
  const [amaDeCasaIdDistrito, setAmaDeCasaIdDistrito] = useState("");

  const [listPais, setListPais] = useState([]);
  const [idPais, setIdPais] = useState("");
  const [dataApiPais, setDataApiPais] = useState(false);
  const [textPais, setTextPais] = useState("");
  const [listNacionalidad, setListNacionalidad] = useState([]);
  const [idNacionalidad, setIdNacionalidad] = useState("");
  const [dataApiNacionalidad, setDataApiNacionalidad] = useState(false);
  const [textNacionalidad, setTextNacionalidad] = useState("");
  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [idEstadoCivil, setIdEstadoCivil] = useState("");
  const [listPuesto, setListPuesto] = useState([]);
  const [listParentesco, setListParentesco] = useState([]);
  //const [inputsInformacionLaboral, setInputsInformacionLaboral] = useState([{"id_info_laboral": 0}]);
  //const [inputsInformacionLaboral, setInputsInformacionLaboral] = useState([{"id_info_laboral": 0, "id_localizacion": 6519}]);
  const [inputsInformacionLaboral, setInputsInformacionLaboral] = useState([
    { id_info_laboral: 0 },
  ]);
  //const [inputsReferencias, setInputsReferencias] = useState([{"id_referencia": 0, "pare_nombre": "AMIGO (A)"}]);
  const [inputsReferencias, setInputsReferencias] = useState([
    { id_referencia: 0 },
  ]);
  const [sucursal, setSucursal] = useState("");
  const [tipoIdentificacion, setTipoIdentificacion] = useState("");
  const [montoMaximoAprobado, setMontoMaximoAprobado] = useState("");
  const [montoSolicitado, setMontoSolicitado] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  const [provincia, setProvincia] = useState("");
  const [canton, setCanton] = useState("");
  const [distrito, setDistrito] = useState("");
  const [poblado, setPoblado] = useState("");
  const [barrio, setBarrio] = useState("");
  const [idLocalizacion, setidLocalizacion] = useState("");
  const [direccion, setDireccion] = useState("");

  const [provinciaInfoLaboral, setProvinciaInfoLaboral] = useState([""]);
  const [textProvinciaLaboralAmaDeCasa, setTextProvinciaLaboralAmaDeCasa] =
    useState("");
  const [textProvinciaLaboral1, setTextProvinciaLaboral1] = useState("");
  const [cantonLaboral, setCantonLaboral] = useState([""]);
  const [distritoLaboral, setDistritoLaboral] = useState([""]);
  const [barrioLaboral, setBarrioLaboral] = useState([""]);
  const [pobladoLaboral, setPobladoLaboral] = useState([""]);

  const [listCantonLaboral, setListCantonLaboral] = useState([]);
  const [textCantonLaboral, setTextCantonLaboral] = useState(""); //AmaDeCasa
  const [textCantonLaboral1, setTextCantonLaboral1] = useState("");
  const [textCantonLaboralAmaDeCasa, setTextCantonLaboralAmaDeCasa] =
    useState("");
  const [listDistritoLaboral, setListDistritoLaboral] = useState([]);
  const [textDistritoLaboral, setTextDistritoLaboral] = useState("");
  const [textDistritoLaboral1, setTextDistritoLaboral1] = useState("");
  const [textDistritoLaboralAmaDeCasa, setTextDistritoLaboralAmaDeCasa] =
    useState("");

  const [listBarrioLaboral, setListBarrioLaboral] = useState([]);
  const [textBarrioLaboral, setTextBarrioLaboral] = useState("");
  const [provinciaApi, setProvinciaApi] = useState("");

  const [listPobladoLaboral, setListPobladoLaboral] = useState([]);
  const [textPobladoLaboral, setTextPobladoLaboral] = useState("");

  const [errorPais, setErrorPais] = useState("");
  const [errorNacionalidad, setErrorNacionalidad] = useState("");
  const [errorEstadoCivil, setErrorEstadoCivil] = useState("");
  const [errorMontoSolicitado, setErrorMontoSolicitado] = useState("");
  const [errorDireccion, setErrorDireccion] = useState("");
  const [errorTelefonoReferencia, setErrorTelefonoReferencia] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [errorChecked, setErrorChecked] = useState("");

  //Texto para disclaimer
  const [textDisclaimer, setTextDisclaimer] = useState("");

  //no se ponen tipo state ya que generan renders infinitos por el tipo de guardado de valor
  let validacionLaboral;
  let validacionReferencias;

  const limiteCaracteresDireccion = 260;
  const limiteCaracteresLugarTrabajo = 100;
  const limiteCaracteresNombreReferencia = 50;
  const limiteCaracteresApellido1Referencia = 50;
  const limiteCaracteresApellido2Referencia = 50;

  //Mascara para el telefono. Ej: 8888-7777
  const MaskTelefono = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

  //texto para el placeholder de dirección con señas
  const textDireccionConSenas =
    "Nota: Brinde información detallada como por ejemplo: nombre de calle, urbanización, condominio o apartamentos, color de casa y número si lo tuviese; además su ubicación (derecha o izquierda). También puede indicar si aplica, distancia en metros o kilómetros del punto de referencia dado.";

  //State para modal del boton Enviar Solicitud
  const [lgShowRespuestaSolicitud, setLgShowRespuestaSolicitud] =
    useState(false);
  const lgHandleCloseRespuestaSolicitud = () =>
    setLgShowRespuestaSolicitud(false);

  //evento para cambiar el valor si se elijio o no el disclaimer
  const handleChangeDisclaimer = (event) => {
    setIsChecked(event.target.checked);
    setErrorChecked("");
  };

  //Información Laboral

  //Se actualiza el valor de cada campo digitado en el array donde se guardan los datos
  //Es un array previendo a futuro que se solicite mas de una información laboral
  //Al inicio si eran varios y luego solcitaron que fuera solo uno, pero que esto pudiera luego cambiar
  const handleInputChangeInformacionLaboral = (
    index,
    event,
    nameInput,
    amaDeCasa,
    validacionSiEsInfoLab
  ) => {
    if (nameInput === undefined || nameInput === "") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        [event.target.name]: event.target.value,
      };
      setInputsInformacionLaboral(newInputs);
    } else if (nameInput === "provincia") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["provinciaLaboral"]: event.value,
      };
      setInputsInformacionLaboral(newInputs);

      //Se buscan los cantones que tienen la provincia elegida
      const valorCanton = cantonLaboral.filter(function (item) {
        return item.loc_nodo_padre === event.value;
      });

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelect = valorCanton.map((item) => ({
        value: item.id_localizacion,
        label: item.loc_nombre,
      }));

      setListCantonLaboral(prepararDatosSelect);
      setListDistritoLaboral([]);
      setListBarrioLaboral([]);
      setListPobladoLaboral([]);

      if (!amaDeCasa) {
        if (validacionSiEsInfoLab) {
          setTextCantonLaboral1("");
          setTextDistritoLaboral1("");
          setTextProvinciaLaboral1(event);
        } else {
          setTextCantonLaboral("");
          setTextDistritoLaboral("");
          setTextBarrioLaboral("");
          setTextPobladoLaboral("");
          setProvinciaApi(event);
        }
      }

      if (amaDeCasa) {
        setTextProvinciaLaboralAmaDeCasa(event);
        setTextCantonLaboralAmaDeCasa("");
        setTextDistritoLaboralAmaDeCasa("");
      }
    } else if (nameInput === "canton") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["cantonLaboral"]: event.value,
      };
      setInputsInformacionLaboral(newInputs);

      //Se buscan los cantones que tienen la provincia elegida
      const valorDistrito = distritoLaboral.filter(function (item) {
        return item.loc_nodo_padre === event.value;
      });

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelect = valorDistrito.map((item) => ({
        value: item.id_localizacion,
        label: item.loc_nombre,
      }));

      //se muestra el texto del valor elegido
      if (amaDeCasa) {
        setTextCantonLaboralAmaDeCasa(event);
        setTextDistritoLaboralAmaDeCasa("");
      } else {
        if (validacionSiEsInfoLab) {
          setTextDistritoLaboral1("");
          setTextCantonLaboral1(event);
        } else {
          setTextCantonLaboral(event);
          //se limpia el texto que se muestra en el select
          setTextDistritoLaboral("");
          setTextBarrioLaboral("");
          setTextPobladoLaboral("");
        }
      }

      //se guarda en el state la lista de cantones
      setListDistritoLaboral(prepararDatosSelect);
    } else if (nameInput === "distrito") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["id_localizacion"]: event.value,
      };
      setInputsInformacionLaboral(newInputs);

      //Se buscan los cantones que tienen la provincia elegida
      const valorBarrio = barrioLaboral.filter(function (item) {
        return item.loc_nodo_padre === event.value;
      });

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelect = valorBarrio.map((item) => ({
        value: item.id_localizacion,
        label: item.loc_nombre,
      }));

      //se muestra el texto del valor elegido
      if (amaDeCasa) {
        setTextDistritoLaboralAmaDeCasa(event);
      } else {
        if (validacionSiEsInfoLab) {
          setTextDistritoLaboral1(event);
        } else {
          setTextDistritoLaboral(event);
          setTextBarrioLaboral("");
          setTextPobladoLaboral("");
        }
      }

      setListBarrioLaboral(prepararDatosSelect);
    } else if (nameInput === "barrio") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["barrio"]: event.value,
      };
      setInputsInformacionLaboral(newInputs);

      const valorPoblado = pobladoLaboral.filter(function (item) {
        return item.loc_nodo_padre === event.value;
      });

      //Se setean conforme lo pide el componente Select
      const prepararDatosSelect = valorPoblado.map((item) => ({
        value: item.id_localizacion,
        label: item.loc_nombre,
      }));

      //se muestra el texto del valor elegido
      setTextBarrioLaboral(event);

      setTextPobladoLaboral("");

      setListPobladoLaboral(prepararDatosSelect);
    } else if (nameInput === "poblado") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["poblado"]: event.value,
      };
      setInputsInformacionLaboral(newInputs);

      //se muestra el texto del valor elegido
      setTextPobladoLaboral(event);
    } else if (nameInput === "lab_telefono") {
      const telefonoSinGuiones = event.target.value.replace(/-/g, "");

      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = {
        ...newInputs[index],
        ["lab_telefono"]: telefonoSinGuiones,
      };
      setInputsInformacionLaboral(newInputs);
    } else if (nameInput === "id_puesto") {
      const newInputs = [...inputsInformacionLaboral];
      newInputs[index] = { ...newInputs[index], ["id_puesto"]: event.value };
      setInputsInformacionLaboral(newInputs);
    }
  };

  //función al darle boton agregar nuevos campos
  //Actualmente no se usa pero se deja previendo usarla a futuro si la solicitan
  //Al princio se usaba, pero pidieron dejar la prevista
  const addNewInputsInformacionLaboral = () => {
    setInputsInformacionLaboral([
      ...inputsInformacionLaboral,
      { id_info_laboral: 0 },
    ]);
  };

  //función al darle boton agregar nuevos campos
  //Actualmente no se usa pero se deja previendo usarla a futuro si la solicitan
  //Al princio se usaba, pero pidieron dejar la prevista
  const removeInputsInformacionLaboral = (index) => {
    const newInputs = inputsInformacionLaboral.filter((_, i) => i !== index);
    setInputsInformacionLaboral(newInputs);
  };

  //Función para revisar si hay un input vacio
  const verificarClavesYValoresInformacionLaboral = (array) => {
    const clavesRequeridas = [
      "lab_nombre",
      "lab_telefono",
      "id_puesto",
      "provinciaLaboral",
      "cantonLaboral",
      "id_localizacion",
    ];

    if (array.length === 0) {
      return false;
    }

    return array.map((objeto) => {
      if (objeto.hasOwnProperty("lab_telefono")) {
        if (objeto.lab_telefono.length < 8) {
          return false;
        }
      }

      // Verifica que todas las claves requeridas estén presentes y tengan un valor no vacío
      for (let clave of clavesRequeridas) {
        if (
          !objeto.hasOwnProperty(clave) ||
          objeto[clave] === "" ||
          objeto[clave] == null
        ) {
          return false;
          // return 'Objeto incompleto o con claves vacías';
        }
      }

      // Si todas las claves están presentes y tienen valores no vacíos
      return true;
      // return 'Objeto válido';
    });
  };

  //respuesta de si hay inputs vacios
  let datosValidadosInformacionLaboral =
    verificarClavesYValoresInformacionLaboral(inputsInformacionLaboral);

  validacionLaboral = datosValidadosInformacionLaboral;

  //Función para revisar si exister un array vacio( conjunto de inputs al darle al boton agregar trabajo)
  //Actualmente no se usa pero se deja previendo usarla a futuro si la solicitan
  //Al princio se usaba, pero pidieron dejar la prevista
  const verificarTodosValidosInformacionLaboral = (array) => {
    // return array.every(elemento => elemento === 'Objeto válido');
    return array.every((elemento) => elemento === true);
  };

  //Referencias Familiares-Personales

  //se actualiza el valor de cada campo digitado
  const handleInputChangeReferencias = (index, event) => {
    //En referencias el unico select es Parentezco
    if (event.label !== undefined && event.value !== undefined) {
      const newInputs = [...inputsReferencias];
      newInputs[index] = {
        ...newInputs[index],
        ["id_parentesco"]: event.value,
        ["pare_nombre"]: event.label,
      };
      setInputsReferencias(newInputs);
    }
    //validación Nombre referencias y apellidos
    else if (
      event.target.name === "ref_nombre" ||
      event.target.name === "ref_apellido1" ||
      event.target.name === "ref_apellido2"
    ) {
      // Permitir solo letras (alfabéticos)
      if (/^[a-zA-Z ]*$/.test(event.target.value)) {
        const newInputs = [...inputsReferencias];
        newInputs[index] = {
          ...newInputs[index],
          [event.target.name]: event.target.value,
        };
        setInputsReferencias(newInputs);
      }
    }
    //telefono 8 digitos y que inicie con 6,7 u 8
    else if (event.target.name === "ref_tel_cel") {
      const inputText = event.target.value;
      const firstDigit = inputText.charAt(0);

      const newValue = event.target.value;

      // Permitir solo números y el guion
      const sanitizedValue = newValue.replace(/[^0-9-]/g, "");

      const TelefonoSinGuiones = sanitizedValue.replace(/-/g, "");

      if (!["6", "7", "8"].includes(firstDigit)) {
        setErrorTelefonoReferencia("8 numeros e iniciar con 6, 7 u 8");

        Swal.fire("Verifica tus datos", "Teléfono debe iniciar con 6, 7 u 8.");
      } else {
        setErrorTelefonoReferencia("");

        if (TelefonoSinGuiones.length < 8) {
          setErrorTelefonoReferencia("8 numeros e iniciar con 6, 7 u 8");

          const newInputs = [...inputsReferencias];
          newInputs[index] = {
            ...newInputs[index],
            [event.target.name]: TelefonoSinGuiones,
          };
          setInputsReferencias(newInputs);
        } else if (TelefonoSinGuiones.length > 8) {
          Swal.fire(
            "Verifica tus datos",
            "Teléfono no puede ser mayor a 8 numeros."
          );
        } else {
          const newInputs = [...inputsReferencias];
          newInputs[index] = {
            ...newInputs[index],
            [event.target.name]: TelefonoSinGuiones,
          };
          setInputsReferencias(newInputs);
        }
      }
    } else {
      const newInputs = [...inputsReferencias];
      newInputs[index] = {
        ...newInputs[index],
        [event.target.name]: event.target.value,
      };
      setInputsReferencias(newInputs);
    }
  };

  //función al darle boton agregar nuevos campos
  const addNewInputsReferencias = () => {
    if (inputsReferencias.length < 3) {
      setInputsReferencias([...inputsReferencias, { id_referencia: 0 }]);
    } else {
      Swal.fire("Mensaje de información", "Solo puedes agregar 3 rerefencias.");
    }
  };

  //función al darle boton agregar nuevos campos
  const removeInputsReferencias = (index) => {
    const newInputs = inputsReferencias.filter((_, i) => i !== index);
    setInputsReferencias(newInputs);
  };

  //Función para revisar si hay un input vacio
  const verificarClavesYValoresReferencias = (array) => {
    const clavesRequeridas = [
      "ref_nombre",
      "ref_apellido1",
      "ref_apellido2",
      "id_parentesco",
      "pare_nombre",
      "ref_tel_cel",
    ];

    if (array.length === 0) {
      return false;
    }

    return array.map((objeto) => {
      // Verifica que todas las claves requeridas estén presentes y tengan un valor no vacío
      for (let clave of clavesRequeridas) {
        if (objeto.hasOwnProperty("ref_tel_cel")) {
          if (objeto.ref_tel_cel.length < 8) {
            return false;
          }
        }

        if (
          !objeto.hasOwnProperty(clave) ||
          objeto[clave] === "" ||
          objeto[clave] == null
        ) {
          const nameObjet = objeto;

          //Para buscar si los arrays contienen telefonos con menos de 8 digitos
          if (nameObjet !== undefined) {
            // const res = 'telefonoReferencias' in objeto[clave];
            const res = objeto.hasOwnProperty("telefonoReferencias");

            if (res === true) {
              const valor = objeto.telefonoReferencias;
              // const esOchoDigitos = /^[0-9]{9}$/.test(valor);
              //const esOchoDigitos = valor.length;

              if (valor.length === 9) {
                //telefono validado true pero vuelve a evaluar para ver si los demas campos están vacios
                if (
                  !objeto.hasOwnProperty(clave) ||
                  objeto[clave] === "" ||
                  objeto[clave] == null
                ) {
                  // return 'Objeto incompleto o con claves vacías';
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        }
      }

      return true;
    });
  };

  //respuesta de si hay inputs vacios
  let datosValidadosReferencias =
    verificarClavesYValoresReferencias(inputsReferencias);
  validacionReferencias = datosValidadosReferencias;

  //Función para revisar si exister un array vacio( conjunto de inputs al darle al boton agregar trabajo)
  const verificarTodosValidosReferencias = (array) => {
    return array.every((elemento) => elemento === true);
  };

  const checkArray = async (array) => {
    if (array === false) {
      //Validación por si está vacio el array de referencia familiar personal
      await Swal.fire(
        "Mensaje de información",
        "Debes incluir al menos una referencia laboral familiar personal"
      );

      return false;
    } else if (array.length === 0) {
      return false;
    } else {
      // Filtrar para encontrar todos los elementos false
      const falseElements = array.filter((element) => element === false);

      // Comprobar si hay exactamente un elemento false
      if (falseElements.length === 1) {
        return false;
      } else {
        // Si no hay elementos false, entonces todos son true
        return true;
      }
    }
  };

  //Función para validar y enviar datos de Solicitud
  const handleClickButtonEnviarSolicitud = async () => {
    const verificarIdSolicitud = localStorage.getItem("id-pre-solicitud-mc");

    //Se valida si existe en memoria el id solicitud, ya que pudo haber sido borrado al limpiar cache
    if (
      verificarIdSolicitud === null ||
      verificarIdSolicitud === undefined ||
      verificarIdSolicitud === ""
    ) {
      await Swal.fire({
        title: "Mensaje de información",
        text: "# de solicitud no encontrado. Favor usar una sola pestaña de este formulario por navegador para evitar inconvenientes de datos.",
        confirmButtonColor: "#001642",
        confirmButtonText: "Continuar",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
        }
      });
    }
    //Si existe el id solicitud
    else {
      //false, no hay errores
      let errorEnDatosParaEnviar = false;
      let mensajeValidacion = "Completa todos los campos obligatorios \n \n";

      //Validaciones

      //Información Laboral

      if (montoSolicitado.length <= 0) {
        setErrorMontoSolicitado(
          "Debe estar entre 55000 y " + montoMaximoAprobado
        );
        errorEnDatosParaEnviar = true;
      } else if (montoSolicitado <= 54999) {
        setErrorMontoSolicitado(
          "Debe estar entre 55000 y " + montoMaximoAprobado
        );
        errorEnDatosParaEnviar = true;
      } else if (montoSolicitado > montoMaximoAprobado) {
        setErrorMontoSolicitado(
          "Debe estar entre 55000 y " + montoMaximoAprobado
        );
        errorEnDatosParaEnviar = true;
      }

      if (idPais.length <= 0) {
        setErrorPais("Requerido");
        errorEnDatosParaEnviar = true;
      }

      if (idNacionalidad.length <= 0) {
        setErrorNacionalidad("Requerida");
        errorEnDatosParaEnviar = true;
      }

      if (idEstadoCivil.length <= 0) {
        setErrorEstadoCivil("Requerido");
        errorEnDatosParaEnviar = true;
      }

      if (direccion.length <= 0) {
        setErrorDireccion("Requerida");
        errorEnDatosParaEnviar = true;
      }

      //Si no es ama de casa, que se realice la verificación
      if (!dataApiAmaDeCasa) {
        const verificacionLaboral = await checkArray(validacionLaboral);
        if (verificacionLaboral === false) {
          mensajeValidacion += ", Información Laboral \n \n";

          errorEnDatosParaEnviar = true;
        }
      }

      //Referencias familiares - personales
      const verificacionReferencias = await checkArray(validacionReferencias);

      if (verificacionReferencias === false) {
        mensajeValidacion += "y Familiares-Personales \n \n";

        errorEnDatosParaEnviar = true;
      }

      if (!isChecked) {
        setErrorChecked("Requerido");
        errorEnDatosParaEnviar = true;
      }

      if (!errorEnDatosParaEnviar) {
        //se deja de mostrar modal con spinner
        setLgShowRespuestaSolicitud(true);

        const id_m_solicitud = localStorage.getItem("id-pre-solicitud-mc");

        let infoLaboral;
        //si es ama de casa se construye nuevo json
        if (dataApiAmaDeCasa) {
          infoLaboral = [
            {
              id_info_laboral: 0,
              lab_nombre: amaDeCasaLugar,
              lab_telefono: amaDeCasaTelefono,
              id_puesto: amaDeCasaIdPuesto,
              id_localizacion: textDistritoLaboralAmaDeCasa.value, //amaDeCasaIdDistrito,
            },
          ];
        }
        //si no es ama de casa, se envia el json de los datos ingresados
        else {
          infoLaboral = inputsInformacionLaboral;
        }

        console.log({
          id_m_solicitud: parseInt(id_m_solicitud),
          msol_monto_solicitado: parseInt(montoSolicitado),
          id_pais_nacimiento: parseInt(idPais),
          id_nacionalidad: parseInt(idNacionalidad),
          id_estado_civil: parseInt(idEstadoCivil),
          per_direccion: direccion,
          trabajos: infoLaboral,
          referencias: inputsReferencias,
          idLocalizacion:
            textPobladoLaboral.value == undefined
              ? idLocalizacion
              : textPobladoLaboral.value,
        });
        console.log(inputsInformacionLaboral);

        //Se envian datos al API
        await sendDataSolicitud({
          id_m_solicitud: parseInt(id_m_solicitud),
          msol_monto_solicitado: parseInt(montoSolicitado),
          id_pais_nacimiento: parseInt(idPais),
          id_nacionalidad: parseInt(idNacionalidad),
          id_estado_civil: parseInt(idEstadoCivil),
          per_direccion: direccion,
          trabajos: infoLaboral,
          referencias: inputsReferencias,
          idLocalizacion: textPobladoLaboral.value,
        });

        //se deja de mostrar modal con spinner
        setLgShowRespuestaSolicitud(false);
      } else {
        await Swal.fire("Verifica tus datos", mensajeValidacion);

        //se deja de mostrar modal con spinner
        setLgShowRespuestaSolicitud(false);
      }
    }
  };

  //Debe estar entre 55000 y el monto máximo aprobado
  const handleChangeMontoSolicitado = (event) => {
    setErrorMontoSolicitado("");

    const valorIngresado = event.target.value;

    // Permitir solo números
    if (/^[0-9]*$/.test(valorIngresado)) {
      if (valorIngresado <= 54999) {
        setMontoSolicitado(valorIngresado);
        setErrorMontoSolicitado(
          "Debe estar entre 55000 y " + montoMaximoAprobado
        );
      } else if (
        valorIngresado >= 55000 &&
        valorIngresado <= montoMaximoAprobado
      ) {
        setMontoSolicitado(valorIngresado);
        setErrorMontoSolicitado("");
      } else {
        Swal.fire(
          "Mensaje de información",
          "Monto debe estar entre 55000 y " + montoMaximoAprobado
        );
      }
    }
  };

  //se establece el valor del pais de nacimiento
  const handleChangePaisNacimiento = (event) => {
    setErrorPais("");
    setIdPais(event.value);
  };

  //se establece el valor de Nacionalidad
  const handleChangeNacionalidad = (event) => {
    setErrorNacionalidad("");
    setIdNacionalidad(event.value);
  };

  //se establece el valor del estado civil
  const handleChangeEstadoCivil = (event) => {
    setErrorEstadoCivil("");
    setIdEstadoCivil(event.value);
  };

  //se establece el valor de la dirección con señas
  const handleChangeDireccion = (event) => {
    setErrorDireccion("");

    const textoIngresado = event.target.value;

    if (textoIngresado.length <= limiteCaracteresDireccion) {
      if (textoIngresado.length === 0) {
        Swal.fire(
          "Mensaje de información",
          "Dirección con señas no puede quedar en blanco."
        );

        setDireccion(textoIngresado);
        setErrorDireccion("Campo obligatorio");
      } else {
        setDireccion(textoIngresado);
      }
    } else {
      Swal.fire(
        "Mensaje de información",
        "No puedes digitar mas de 260 caracteres"
      );

      setErrorDireccion("");
    }
  };

  //Función para encontrar el texto de pais y gentilicio según el id
  const findCountryById = (array, idToFind) => {
    const foundItem = array.find((item) => item.id_pais === idToFind);

    return foundItem
      ? {
          pais_nombre: foundItem.pais_nombre,
          pais_gentilicio: foundItem.pais_gentilicio,
        }
      : null;
  };

  //Se obtienen datos del API cuando se carga la pagina por primera vez
  const getlistFirstDataSolicitud = async () => {
    try {
      const resultListFirstDataSolicitud = await listFirstDataSolicitud();

      if (resultListFirstDataSolicitud !== undefined) {
        //Se setean conforme lo pide el componente Select
        const prepararDatosSelectPais =
          resultListFirstDataSolicitud.data.paises.map((item) => ({
            value: item.id_pais,
            label: item.pais_nombre,
          }));

        const prepararDatosSelectNacionalidad =
          resultListFirstDataSolicitud.data.paises.map((item) => ({
            value: item.id_pais,
            label: item.pais_gentilicio,
          }));

        let infoTipoDocumentos = "";

        if (resultListFirstDataSolicitud.data.id_perdoc === 1) {
          infoTipoDocumentos = "Cédula de Nacional";
        } else {
          infoTipoDocumentos = "Cédula de Residencia";
        }

        const prepararDatosSelectEstadoCivil =
          resultListFirstDataSolicitud.data.estados_civiles.map((item) => ({
            value: item.id_estado_civil,
            label: item.nombre_estado_civil,
          }));

        const prepararDatosSelectParentesco =
          resultListFirstDataSolicitud.data.parentezcos.map((item) => ({
            value: item.id_parentesco,
            label: item.pare_nombre,
          }));

        const prepararDatosSelectPuestos =
          resultListFirstDataSolicitud.data.puestos.map((item) => ({
            value: item.id_puesto,
            label: item.puesto_nombre,
          }));

        //Datos para Select
        setListPais(prepararDatosSelectPais);
        setListNacionalidad(prepararDatosSelectNacionalidad);
        setListEstadoCivil(prepararDatosSelectEstadoCivil);
        setListParentesco(prepararDatosSelectParentesco);
        setListPuesto(prepararDatosSelectPuestos);

        //Datos para inputs
        setSucursal(resultListFirstDataSolicitud.data.suc_det_nombre);
        setMontoMaximoAprobado(resultListFirstDataSolicitud.data.monto_maximo);
        setTipoIdentificacion(infoTipoDocumentos);
        setIdentificacion(resultListFirstDataSolicitud.data.per_cedula);
        setNombre(resultListFirstDataSolicitud.data.per_nombre);
        setApellido1(resultListFirstDataSolicitud.data.per_apellido1);
        setApellido2(resultListFirstDataSolicitud.data.per_apellido2);
        setFechaNacimiento(resultListFirstDataSolicitud.data.per_fecha_nac);
        setTelefono(resultListFirstDataSolicitud.data.per_telefono);
        setCorreoElectronico(resultListFirstDataSolicitud.data.per_correo);

        /// Datos para determinar la localizacion inical del formulario tanto para si es asalariado o no
        if(resultListFirstDataSolicitud.data.id_localizacion) { 
          var pob =  resultListFirstDataSolicitud.data.localizaciones[4].find(c => c.id_localizacion === resultListFirstDataSolicitud.data.id_localizacion );
          var barr = resultListFirstDataSolicitud.data.localizaciones[3].find(c => c.id_localizacion === resultListFirstDataSolicitud.data.id_localizacion || c.id_localizacion === pob?.loc_nodo_padre );
          var dist = resultListFirstDataSolicitud.data.localizaciones[2].find(c => c.id_localizacion === resultListFirstDataSolicitud.data.id_localizacion || c.id_localizacion === barr?.loc_nodo_padre );
          var cant = resultListFirstDataSolicitud.data.localizaciones[1].find(c => c.id_localizacion === resultListFirstDataSolicitud.data.id_localizacion || c.id_localizacion === dist?.loc_nodo_padre );
          var prov = resultListFirstDataSolicitud.data.localizaciones[0].find(c => c.id_localizacion === resultListFirstDataSolicitud.data.id_localizacion || c.id_localizacion === cant?.loc_nodo_padre);
          
            // Determina si existe la provincia para inicializarla
            if(prov?.id_localizacion){
              setProvinciaApi({ value: prov?.id_localizacion, label: prov?.loc_nombre} );
              if(resultListFirstDataSolicitud.data.es_ama_de_casa){
                setTextProvinciaLaboralAmaDeCasa({ value: prov?.id_localizacion, label: prov?.loc_nombre} );
              }
            }

            setListCantonLaboral(resultListFirstDataSolicitud.data.localizaciones[1].filter(c => c.loc_nodo_padre===cant.loc_nodo_padre || c.loc_nodo_padre===prov?.id_localizacion).map((item) => ({
              value: item.id_localizacion,
              label: item.loc_nombre
            })))

            // Determina si existe el canton para inicializarla
            if(cant?.id_localizacion){
              setTextCantonLaboral( { value: cant?.id_localizacion, label: cant?.loc_nombre})
              if(resultListFirstDataSolicitud.data.es_ama_de_casa){
                setTextCantonLaboralAmaDeCasa({ value: cant?.id_localizacion, label: cant?.loc_nombre});
              }
            }

            setListDistritoLaboral( resultListFirstDataSolicitud.data.localizaciones[2].filter(c => c.loc_nodo_padre===dist?.loc_nodo_padre || c.loc_nodo_padre===cant?.id_localizacion).map((item) => ({
              value: item.id_localizacion,
              label: item.loc_nombre
            })))

            // Determina si existe el distrito para inicializarla
            if(dist?.id_localizacion){
              setTextDistritoLaboral({ value: dist?.id_localizacion, label: dist?.loc_nombre})
              if(resultListFirstDataSolicitud.data.es_ama_de_casa){
                setTextDistritoLaboralAmaDeCasa({ value: dist?.id_localizacion, label: dist?.loc_nombre});
              }
            }
          
            setListBarrioLaboral( resultListFirstDataSolicitud.data.localizaciones[3].filter(c => c.loc_nodo_padre===barr?.loc_nodo_padre || c.loc_nodo_padre===dist?.id_localizacion).map((item) => ({
              value: item.id_localizacion,
              label: item.loc_nombre
            })))

            // Determina si existe el barrio para inicializarla
            if(barr?.id_localizacion){
              setTextBarrioLaboral({ value: barr?.id_localizacion, label: barr?.loc_nombre});
            }
            
            setListPobladoLaboral( resultListFirstDataSolicitud.data.localizaciones[4].filter(c => c.loc_nodo_padre===pob?.loc_nodo_padre || c.loc_nodo_padre===barr?.id_localizacion).map((item) => ({
              value: item.id_localizacion,
              label: item.loc_nombre
            })))

            // Determina si existe el poblado para inicializarla
            if(pob?.id_localizacion){
              setTextPobladoLaboral({ value: pob?.id_localizacion, label: pob?.loc_nombre});
            } 
          setidLocalizacion(resultListFirstDataSolicitud.data.id_localizacion);
        }

        //Texto para disclaimer
        setTextDisclaimer(resultListFirstDataSolicitud.data.disclaimer);

        if (resultListFirstDataSolicitud.data.id_pais_nacimiento !== 0) {
          setIdPais(resultListFirstDataSolicitud.data.id_pais_nacimiento);

          const textPaisEncontrado = findCountryById(
            resultListFirstDataSolicitud.data.paises,
            resultListFirstDataSolicitud.data.id_pais_nacimiento
          );
          const { pais_nombre } = textPaisEncontrado;

          setTextPais(pais_nombre);
          setDataApiPais(true);
        }

        if (resultListFirstDataSolicitud.data.id_nacionalidad !== 0) {
          setIdNacionalidad(resultListFirstDataSolicitud.data.id_nacionalidad);

          const textPaisEncontrado = findCountryById(
            resultListFirstDataSolicitud.data.paises,
            resultListFirstDataSolicitud.data.id_nacionalidad
          );
          const { pais_gentilicio } = textPaisEncontrado;

          setTextNacionalidad(pais_gentilicio);
          setDataApiNacionalidad(true);
        }

        if (resultListFirstDataSolicitud.data.es_ama_de_casa) {
          const buscarIdAmaDeCasa = (array, labelBuscado) => {
            const resultId = array.find(
              (elemento) => elemento.label === labelBuscado
            );

            return resultId ? resultId.value : null;
          };

          const idPuestoAmaDeCasa = buscarIdAmaDeCasa(
            prepararDatosSelectPuestos,
            "Ama de Casa"
          );

          setDataApiAmaDeCasa(true);

          setAmaDeCasaLugar("Ama de casa");
          setAmaDeCasaTelefono(resultListFirstDataSolicitud.data.per_telefono);
          setAmaDeCasaTextPuesto("Ama de casa");
          setAmaDeCasaIdPuesto(idPuestoAmaDeCasa);
          setAmaDeCasaTextProvincia(
            resultListFirstDataSolicitud.data.loc_provincia
          );
          setAmaDeCasaTextCanton(resultListFirstDataSolicitud.data.loc_canton);
          setAmaDeCasaTextDistrito(
            resultListFirstDataSolicitud.data.loc_distrito
          );

          //   Filtrar y guardar el array que contiene las provincias
          setProvinciaInfoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[0]
          );
          setCantonLaboral(resultListFirstDataSolicitud.data.localizaciones[1]);
          setDistritoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[2]
          );

          setBarrioLaboral(resultListFirstDataSolicitud.data.localizaciones[3]);

          setPobladoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[4]
          );
          //   setProvincia(resultListFirstDataSolicitud.data.localizaciones[0]);
          localStorage.setItem(
            "lista-provincias-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[0])
          );

          // Filtrar y guardar el array que contiene las canton
          //   setCanton(resultListFirstDataSolicitud.data.localizaciones[1]);
          localStorage.setItem(
            "lista-canton-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[1])
          );

          // Filtrar y guardar el array que contiene las distrito
          //   setDistrito(resultListFirstDataSolicitud.data.localizaciones[2]);
          localStorage.setItem(
            "lista-distrito-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[2])
          );

          localStorage.setItem(
            "lista-barrio-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[3])
          );

          localStorage.setItem(
            "lista-poblado-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[4])
          );

          const recibirProvinciasLaboral = JSON.parse(
            localStorage.getItem("lista-provincias-solicitud-mc")
          );
          const recibirCantonLaboral = JSON.parse(
            localStorage.getItem("lista-canton-solicitud-mc")
          );
          const recibirDistritoLaboral = JSON.parse(
            localStorage.getItem("lista-distrito-solicitud-mc")
          );

          const resultIdProvincia = findValueByLabel(
            recibirProvinciasLaboral,
            resultListFirstDataSolicitud.data.loc_provincia
          );
          const resultIdCanton = findValueByLabel(
            recibirCantonLaboral,
            resultListFirstDataSolicitud.data.loc_canton
          );
          const resultDistrito = findValueByLabel(
            recibirDistritoLaboral,
            resultListFirstDataSolicitud.data.loc_distrito
          );

          setAmaDeCasaIdProvincia(resultIdProvincia);
          setAmaDeCasaIdCanton(resultIdCanton);
          setAmaDeCasaIdDistrito(resultDistrito);
        } else {
          //   Filtrar y guardar el array que contiene las provincias
          setProvinciaInfoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[0]
          );
          setCantonLaboral(resultListFirstDataSolicitud.data.localizaciones[1]);
          setDistritoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[2]
          );

          setBarrioLaboral(resultListFirstDataSolicitud.data.localizaciones[3]);

          setPobladoLaboral(
            resultListFirstDataSolicitud.data.localizaciones[4]
          );
          //   setProvincia(resultListFirstDataSolicitud.data.localizaciones[0]);
          localStorage.setItem(
            "lista-provincias-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[0])
          );

          // Filtrar y guardar el array que contiene las canton
          //   setCanton(resultListFirstDataSolicitud.data.localizaciones[1]);
          localStorage.setItem(
            "lista-canton-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[1])
          );

          // Filtrar y guardar el array que contiene las distrito
          //   setDistrito(resultListFirstDataSolicitud.data.localizaciones[2]);
          localStorage.setItem(
            "lista-distrito-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[2])
          );

          localStorage.setItem(
            "lista-barrio-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[3])
          );

          localStorage.setItem(
            "lista-poblado-solicitud-mc",
            JSON.stringify(resultListFirstDataSolicitud.data.localizaciones[4])
          );

          const recibirProvinciasLaboral = JSON.parse(
            localStorage.getItem("lista-provincias-solicitud-mc")
          );
          const recibirCantonLaboral = JSON.parse(
            localStorage.getItem("lista-canton-solicitud-mc")
          );
          const recibirDistritoLaboral = JSON.parse(
            localStorage.getItem("lista-distrito-solicitud-mc")
          );

          const resultIdProvincia = findValueByLabel(
            recibirProvinciasLaboral,
            resultListFirstDataSolicitud.data.loc_provincia
          );
          const resultIdCanton = findValueByLabel(
            recibirCantonLaboral,
            resultListFirstDataSolicitud.data.loc_canton
          );
          const resultDistrito = findValueByLabel(
            recibirDistritoLaboral,
            resultListFirstDataSolicitud.data.loc_distrito
          );

          getObjetLocalizacion(
            resultListFirstDataSolicitud.data.loc_provincia,
            resultListFirstDataSolicitud.data.localizaciones[0]
          );
          // localStorage.setItem("provinciaApi", provApi);
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      Swal.fire("Mensaje de información", error.message);
    }
  };



  //se obtienen valores del localstorage guardados en la Presolicitud
   const getDataLocation = () => {
    const prov = localStorage.getItem("lista-provincias-solicitud-mc");
    const cant = localStorage.getItem("lista-canton-solicitud-mc");
    const dist = localStorage.getItem("lista-distrito-solicitud-mc");
    const idPr = localStorage.getItem("id-pre-solicitud-mc");

    //Si los datos vienen vacios, se redireccina a la Presolicitud
    if (
      prov === "" ||
      prov === null ||
      prov === undefined ||
      cant === "" ||
      cant === null ||
      cant === undefined ||
      dist === "" ||
      dist === null ||
      dist === undefined ||
      idPr === "" ||
      idPr === null ||
      idPr === undefined
    ) {
      //   navigate("/");
    }
    //Si vienen datos, se procesan para ser usados en los Selects
    else {
      const recibirProvinciasLaboral = JSON.parse(
        localStorage.getItem("lista-provincias-solicitud-mc")
      );
      const recibirCantonLaboral = JSON.parse(
        localStorage.getItem("lista-canton-solicitud-mc")
      );
      const recibirDistritoLaboral = JSON.parse(
        localStorage.getItem("lista-distrito-solicitud-mc")
      );

      if (
        recibirProvinciasLaboral !== "" ||
        recibirCantonLaboral !== "" ||
        recibirDistritoLaboral !== ""
      ) {
         setProvinciaInfoLaboral(recibirProvinciasLaboral);
         setCantonLaboral(recibirCantonLaboral);
         setDistritoLaboral(recibirDistritoLaboral);
      } else {
        navigate("/");
      }
    }
  };

  //Style para los Select
  const customStylesSelect = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
  };

  //Hook que se ejecuta al cargar la pagina
  useEffect(() => {
    async function fetchData() {
      getDataLocation();
      await getlistFirstDataSolicitud();
      // await getObjetLocalizacion(provincia, 0);
    }
    fetchData();
  }, []);

  //Se setean conforme lo pide el componente Select
  const listProvinciasLaboral = provinciaInfoLaboral.map((item) => ({
    value: item.id_localizacion,
    label: item.loc_nombre,
  }));

  /*
    Para cuando es Ama de casa, en los campos de localización se debe mostrar los
    nombres de provincia, canton y distrito del id poblado elegido en la Presolicitud
    Función para encontrar el valor, dando el texto del label
    Se da nombre padre localización y se retorna el id de la localización
    */
  const findValueByLabel = (array, labelToFind) => {
    const foundItem = array.find((item) => item.loc_nombre === labelToFind);

    return foundItem ? foundItem.id_localizacion : null;
  };

  //Función para formatear la fecha cuando viene del API
  const formatDate = (valueDateAPI) => {
    const valueDate = new Date(valueDateAPI);
    const date = new Date(valueDate);
    date.setDate(valueDate.getDate() + 1);
    // Formatear la fecha como "dd/MM/yyyy"
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  //Validación para mostrar el spinner
  if (estadoCargando === true) {
    return <SpinnerCarga title="Cargando formulario..." />;
  }

  //   function getPobladoId(nombrePoblado) {
  //     var map = pobladoLaboral;
  //     var dato;
  //     for (let index = 0; index < map.length; index++) {
  //       dato = map[index];
  //       if (dato && dato.loc_nombre.toString() == nombrePoblado) {
  //         return dato.id_localizacion;
  //       }
  //     }
  //   }

  function getObjetLocalizacion(nombre, numeroEntrada) {
    var map = numeroEntrada;
    // if (numeroEntrada == 0) {
    //   map = provinciaInfoLaboral;
    // } else if (numeroEntrada == 1) {
    //   map = cantonLaboral;
    // } else if (numeroEntrada == 2) {
    //   map = distritoLaboral;
    // } else if (numeroEntrada == 3) {
    //   map = barrioLaboral;
    // } else if (numeroEntrada == 4) {
    //   map = pobladoLaboral;
    // }
    var dato;
    for (let index = 0; index < map.length; index++) {
      dato = map[index];
      if (dato && dato.loc_nombre.toString() == nombre) {
        setProvinciaApi({
          label: dato.loc_nombre,
          value: dato.id_localizacion,
        });
        // {
        //   label: dato.loc_nombre,
        //   value: dato.id_localizacion,
        // };
      }
    }
  }

  //Se renderiza el siguiente JSX
  return (
    <div className="main-container">
      {/* Modal para presentar el spinner al enviar datos al API */}
      {lgShowRespuestaSolicitud === true ? (
        <Modal
          show={lgShowRespuestaSolicitud}
          onHide={lgHandleCloseRespuestaSolicitud}
          size="lg"
          centered
        >
          <Modal.Body>
            <div className="mb-4 mt-4">
              <SpinnerCarga title="Procesando solicitud. Un momento por favor..." />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <></>
      )}
      {/*Header - Logo*/}
      <HeaderLogo src={logo} alt="Logo Credie" />
      {/*Header - IMG */}
      <HeaderImagen
        banner={banner}
        bannerMobile={bannerMobile}
        alt={"Imagen CrediE"}
      />
      <div className="form-container">
        {/* Title */}
        <h4 className="text-center mb-4">Información de la Solicitud</h4>

        {/* Row */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>Monto Máximo aprobado: </label>
            </p>

            {/* Monto máximo aprobado traido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={montoMaximoAprobado}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>
                Monto Solicitado:{" "}
                {errorMontoSolicitado && (
                  <spam style={{ color: "red" }}>{errorMontoSolicitado}</spam>
                )}
              </label>
            </p>

            {/* Debe estar entre 55000 y el monto máximo aprobado */}
            <input
              className="w-100 input-form"
              type="text"
              value={montoSolicitado}
              onChange={handleChangeMontoSolicitado}
            />
          </div>
        </div>

        <h4 className="text-center mb-4">Información del Cliente</h4>

        {/* Row */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Tipo de Identificación: </label>
            </p>

            {/* Tipo de identificación, traido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={tipoIdentificacion}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Identificación: </label>
            </p>

            {/* Cedula, traida del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={identificacion}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Nombre: </label>
            </p>

            {/* Nombre, traido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={nombre}
              readOnly={true}
            />
          </div>
        </div>

        {/* Row */}

        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Primer apellido: </label>
            </p>

            {/* Primer apellido, obtenido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={apellido1}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Segundo apellido: </label>
            </p>

            {/* Segundo apellido, obtenido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={apellido2}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                País de nacimiento:{" "}
                {errorPais && <spam style={{ color: "red" }}>{errorPais}</spam>}
              </label>
            </p>

            {/* Si el api entrega un dato de Pais, se muestra, sino habilitado el campo para digitarlo */}
            {dataApiPais ? (
              <>
                <input
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={textPais}
                  readOnly={true}
                />
              </>
            ) : (
              <Select
                name="pais-select"
                options={listPais}
                className="basic-multi-select mb-3 maxwidth select-form"
                onChange={handleChangePaisNacimiento}
                placeholder="Buscar"
                styles={customStylesSelect}
              />
            )}
          </div>
        </div>

        {/* Row */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Fecha de nacimiento: </label>
            </p>

            {/* Fecha de nacimiento, obtenido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={formatDate(fechaNacimiento)}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Nacionalidad:{" "}
                {errorNacionalidad && (
                  <spam style={{ color: "red" }}>{errorNacionalidad}</spam>
                )}
              </label>
            </p>

            {/* Si el api entrega un dato de Nacionalidad, se muestra, sino habilitado el campo para digitarlo */}
            {dataApiNacionalidad ? (
              <input
                className="w-100 input-form input-bloqueado-color"
                type="text"
                value={textNacionalidad}
                readOnly={true}
              />
            ) : (
              <Select
                name="nacionalidad-select"
                options={listNacionalidad}
                className="basic-multi-select mb-3 maxwidth select-form"
                onChange={handleChangeNacionalidad}
                placeholder="Buscar"
                styles={customStylesSelect}
              />
            )}
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>
                Estado civil:{" "}
                {errorEstadoCivil && (
                  <spam style={{ color: "red" }}>{errorEstadoCivil}</spam>
                )}
              </label>
            </p>

            {/* Estado civil */}
            <Select
              name="estadocivil-select"
              options={listEstadoCivil}
              className="basic-multi-select mb-3 maxwidth select-form"
              onChange={handleChangeEstadoCivil}
              placeholder="Buscar"
              styles={customStylesSelect}
            />
          </div>
        </div>

        {/* Row */}
        <div className="row mb-3">
          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>Teléfono: </label>
            </p>

            {/* Telefono, traido del api  */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={telefono}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>Correo electrónico: </label>
            </p>

            {/* Correo, traido del API */}
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={correoElectronico}
              readOnly={true}
            />
          </div>
        </div>

        <h4 className="text-center mb-4">Localización</h4>

        {inputsInformacionLaboral.map((input, index) => (
          <div key={index}>
            {index !== 0 && <hr></hr>}

            {/* Row */}
            <div className="row mb-3">
              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Provincia: </label>{" "}
                  {!inputsInformacionLaboral[index]?.provinciaLaboral && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                {provincia != "0" ? (
                  <>
                    <Select
                      name="provinciaLaboral"
                      options={listProvinciasLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "provincia"
                        )
                      }
                      value={provinciaApi}
                      //   defaultInputValue={provincia}
                      //   value={localStorage.getItem("provinciaApi")}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                    />
                    <input
                      hidden
                      //   style={{ display: "none" }}
                      className="w-100 input-form input-bloqueado-color"
                      type="text"
                      value={provincia}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <Select
                    name="provinciaLaboral"
                    options={listProvinciasLaboral}
                    className="basic-multi-select mb-3 maxwidth select-form"
                    onChange={(e) =>
                      handleInputChangeInformacionLaboral(index, e, "provincia")
                    }
                    //   defaultInputValue={provincia}

                    placeholder="Buscar"
                    styles={customStylesSelect}
                  />
                )}

                {/* <Select
                  name="provinciaLaboral"
                  options={listProvinciasLaboral}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) =>
                    handleInputChangeInformacionLaboral(index, e, "provincia")
                  }
                  //   defaultInputValue={provincia}

                  placeholder="Buscar"
                  styles={customStylesSelect}
                /> */}
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Cantón: </label>{" "}
                  {!inputsInformacionLaboral[index]?.cantonLaboral && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                {canton != "0" && canton != "" ? (
                  <>
                    <Select
                      name="canton-select"
                      options={listCantonLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(index, e, "canton")
                      }
                      defaultInputValue={canton}
                      value={textCantonLaboral}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                    <input
                      hidden
                      className="w-100 input-form input-bloqueado-color"
                      type="text"
                      value={canton}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <Select
                    name="canton-select"
                    options={listCantonLaboral}
                    className="basic-multi-select mb-3 maxwidth select-form"
                    onChange={(e) =>
                      handleInputChangeInformacionLaboral(index, e, "canton")
                    }
                    //   defaultInputValue={canton}
                    value={textCantonLaboral}
                    placeholder="Buscar"
                    styles={customStylesSelect}
                    readOnly={true}
                  />
                )}

                {/* <Select
                  name="canton-select"
                  options={listCantonLaboral}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) =>
                    handleInputChangeInformacionLaboral(index, e, "canton")
                  }
                  //   defaultInputValue={canton}
                  value={textCantonLaboral}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                  readOnly={true}
                /> */}
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Distrito: </label>{" "}
                  {!inputsInformacionLaboral[index]?.id_localizacion && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                {distrito != "0" && distrito != "" ? (
                  <>
                    <Select
                      name="distrito-select"
                      options={listDistritoLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "distrito"
                        )
                      }
                      defaultInputValue={distrito}
                      value={textDistritoLaboral}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                    <input
                      hidden
                      className="w-100 input-form input-bloqueado-color"
                      type="text"
                      value={distrito}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <Select
                    name="distrito-select"
                    options={listDistritoLaboral}
                    className="basic-multi-select mb-3 maxwidth select-form"
                    onChange={(e) =>
                      handleInputChangeInformacionLaboral(index, e, "distrito")
                    }
                    //   defaultInputValue={distrito}
                    value={textDistritoLaboral}
                    placeholder="Buscar"
                    styles={customStylesSelect}
                    readOnly={true}
                  />
                )}

                {/* <Select
                  name="distrito-select"
                  options={listDistritoLaboral}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) =>
                    handleInputChangeInformacionLaboral(index, e, "distrito")
                  }
                  //   defaultInputValue={distrito}
                  value={textDistritoLaboral}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                  readOnly={true}
                /> */}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 col-lg-6 mb-3">
                <p className="mb-3">
                  <label>Barrio: </label>
                  {!inputsInformacionLaboral[index]?.barrio && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>
                {/* /*Barrio, traido del API */}
                {/* <input
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={barrio}
                  readOnly={true}
                /> */}

                {barrio != "0" && barrio != "" ? (
                  <>
                    <Select
                      name="distrito-select"
                      options={listBarrioLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(index, e, "barrio")
                      }
                      defaultInputValue={barrio}
                      value={textBarrioLaboral}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                    <input
                      hidden
                      className="w-100 input-form input-bloqueado-color"
                      type="text"
                      value={barrio}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <Select
                    name="distrito-select"
                    options={listBarrioLaboral}
                    className="basic-multi-select mb-3 maxwidth select-form"
                    onChange={(e) =>
                      handleInputChangeInformacionLaboral(index, e, "barrio")
                    }
                    //   defaultInputValue={distrito}
                    value={textBarrioLaboral}
                    placeholder="Buscar"
                    styles={customStylesSelect}
                    readOnly={true}
                  />
                )}
                {/* <Select
                  name="distrito-select"
                  options={listBarrioLaboral}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) =>
                    handleInputChangeInformacionLaboral(index, e, "barrio")
                  }
                  //   defaultInputValue={distrito}
                  value={textBarrioLaboral}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                  readOnly={true}
                /> */}
              </div>

              <div className="col-md-12 col-lg-6 mb-3">
                <p className="mb-3">
                  <label>Poblado: </label>
                  {!inputsInformacionLaboral[index]?.poblado && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>
                {/* /* Poblado, traido del API */}
                {/* <input
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={poblado}
                  readOnly={true}
                /> */}

                {poblado != "0" && poblado != "" ? (
                  <>
                    <Select
                      name="distrito-select"
                      options={listPobladoLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(index, e, "poblado")
                      }
                      defaultInputValue={poblado}
                      value={textPobladoLaboral}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                    <input
                      hidden
                      className="w-100 input-form input-bloqueado-color"
                      type="text"
                      value={poblado}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <Select
                    name="distrito-select"
                    options={listPobladoLaboral}
                    className="basic-multi-select mb-3 maxwidth select-form"
                    onChange={(e) =>
                      handleInputChangeInformacionLaboral(index, e, "poblado")
                    }
                    //   defaultInputValue={distrito}
                    value={textPobladoLaboral}
                    placeholder="Buscar"
                    styles={customStylesSelect}
                    readOnly={true}
                  />
                )}

                {/* <Select
                  name="distrito-select"
                  options={listPobladoLaboral}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) =>
                    handleInputChangeInformacionLaboral(index, e, "poblado")
                  }
                  //   defaultInputValue={distrito}
                  value={textPobladoLaboral}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                  readOnly={true}
                /> */}
              </div>
              {/* <center>
                {" "}
                <span style={{ color: "red" }}>
                  **Las ubicaciones no son obligatorias**
                </span>
              </center> */}
            </div>
          </div>
        ))}
        {
        /* Row }
        <div className="row mb-3">
          <div className="col-md-12 col-lg-4 mb-3">
            {/* Provincia, traida del API }
            <p className="mb-3">
              <label>Provincia: </label>
            </p>

            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={provincia}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            {/* Canton, traida del API }
            <p className="mb-3">
              <label>Cantón: </label>
            </p>

            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={canton}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-4 mb-3">
            <p className="mb-3">
              <label>Distrito: </label>
            </p>

            {/* Distrito, traido del API }
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={distrito}
              readOnly={true}
            />
          </div>
        </div>

        {/* Row */}
        {/* <div className="row mb-3">
          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>Barrio: </label>
            </p>

            /*Barrio, traido del API 
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={barrio}
              readOnly={true}
            />
          </div>

          <div className="col-md-12 col-lg-6 mb-3">
            <p className="mb-3">
              <label>Poblado: </label>
            </p>

            /* Poblado, traido del API 
            <input
              className="w-100 input-form input-bloqueado-color"
              type="text"
              value={poblado}
              readOnly={true}
            />
          </div>
        </div> */}

        <h4 className="text-center mb-4">Residencias</h4>

        {/* Row */}
        <div className="row mb-3">
          <div className="col-md-12 mb-3">
            <p className="mb-3">
              <label>
                Dirección con señas:{" "}
                {errorDireccion && (
                  <spam style={{ color: "red" }}>{errorDireccion}</spam>
                )}{" "}
              </label>
            </p>

            {/*Dirección con señas, con placeholder muy detallado */}
            <textarea
              className="w-100 input-form rows-textarea-direccion-con-senas"
              value={direccion}
              //readOnly={true}
              maxLength={limiteCaracteresDireccion}
              onChange={handleChangeDireccion}
              //rows={9}
              placeholder={textDireccionConSenas}
            />
          </div>
        </div>

        <h4 className="text-center mb-4">Información Laboral</h4>

        {/* Si el api devuelve que el usuario ingreso en la Pre, que es ama de casa */}
        {/* Los campos de información laboral se preestablecen con los datos personales */}
        {/* Sino, se habilitan los campos para digitar */}
        {dataApiAmaDeCasa ? (
          <>
            <div className="row mb-3">
              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Lugar de trabajo: </label>{" "}
                </p>

                <input
                  name="lab_nombre"
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={amaDeCasaLugar}
                  readOnly={true}
                />
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Teléfono: </label>
                </p>

                <input
                  name="telefonoLaboral"
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={amaDeCasaTelefono}
                  readOnly={true}
                />
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Puesto: </label>
                </p>

                <input
                  name="lab_puesto"
                  className="w-100 input-form input-bloqueado-color"
                  type="text"
                  value={amaDeCasaTextPuesto}
                  readOnly={true}
                />
              </div>

              {/* UBICACIONES AMA DE CASA */}
              {inputsInformacionLaboral.map((input, index) => (
                <div key={index}>
                  {index !== 0 && <hr></hr>}

                  {/* Row */}
                  <div className="row mb-3">
                    <div className="col-md-12 col-lg-4 mb-3">
                      <p className="mb-3">
                        <label>Provincia: </label>{" "}
                        {!textProvinciaLaboralAmaDeCasa && (
                          <span style={{ color: "red" }}>Requerido</span>
                        )}
                      </p>

                      <Select
                        name="provinciaLaboral"
                        options={listProvinciasLaboral}
                        className="basic-multi-select mb-3 maxwidth select-form"
                        value= {textProvinciaLaboralAmaDeCasa}
                        onChange={(e) =>
                          handleInputChangeInformacionLaboral(
                            index,
                            e,
                            "provincia",
                            true
                          )
                        }
                        placeholder="Buscar"
                        styles={customStylesSelect}
                      />
                    </div>

                    <div className="col-md-12 col-lg-4 mb-3">
                      <p className="mb-3">
                        <label>Cantón: </label>{" "}
                        {!textCantonLaboralAmaDeCasa && (
                          <span style={{ color: "red" }}>Requerido</span>
                        )}
                      </p>

                      <Select
                        name="canton-select"
                        options={listCantonLaboral}
                        className="basic-multi-select mb-3 maxwidth select-form"
                        onChange={(e) =>
                          handleInputChangeInformacionLaboral(
                            index,
                            e,
                            "canton",
                            true
                          )
                        }
                        value={textCantonLaboralAmaDeCasa}
                        placeholder="Buscar"
                        styles={customStylesSelect}
                        readOnly={true}
                      />
                    </div>

                    <div className="col-md-12 col-lg-4 mb-3">
                      <p className="mb-3">
                        <label>Distrito: </label>{" "}
                        {!textDistritoLaboralAmaDeCasa && (
                          <span style={{ color: "red" }}>Requerido</span>
                        )}
                      </p>

                      <Select
                        name="distrito-select"
                        options={listDistritoLaboral}
                        className="basic-multi-select mb-3 maxwidth select-form"
                        onChange={(e) =>
                          handleInputChangeInformacionLaboral(
                            index,
                            e,
                            "distrito",
                            true
                          )
                        }
                        value={textDistritoLaboralAmaDeCasa}
                        placeholder="Buscar"
                        styles={customStylesSelect}
                        readOnly={true}
                      />
                    </div>
                    {/* <center>
                      {" "}
                      <span style={{ color: "red" }}>
                        **Las ubicaciones no son obligatorias**
                      </span>
                    </center> */}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          //   <>
          //     {/* Row */}
          //     {/* En caso de true ama de casa, entonces mostrar inputs con información preelegida en la Pre */}
          //     <div className="row mb-3">
          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Lugar de trabajo: </label>{" "}
          //         </p>

          //         <input
          //           name="lab_nombre"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaLugar}
          //           readOnly={true}
          //         />
          //       </div>

          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Teléfono: </label>
          //         </p>

          //         <input
          //           name="telefonoLaboral"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaTelefono}
          //           readOnly={true}
          //         />
          //       </div>

          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Puesto: </label>
          //         </p>

          //         <input
          //           name="lab_puesto"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaTextPuesto}
          //           readOnly={true}
          //         />
          //       </div>
          //     </div>

          //     {/* Row */}
          //     <div className="row mb-3">
          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Provincia: </label>{" "}
          //         </p>

          //         <input
          //           name="provinciaLaboral"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaTextProvincia}
          //           readOnly={true}
          //         />
          //       </div>

          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Cantón: </label>{" "}
          //         </p>

          //         <input
          //           name="cantonLaboral"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaTextCanton}
          //           readOnly={true}
          //         />
          //       </div>

          //       <div className="col-md-12 col-lg-4 mb-3">
          //         <p className="mb-3">
          //           <label>Distrito: </label>{" "}
          //         </p>

          //         <input
          //           name="distritoLaboral"
          //           className="w-100 input-form input-bloqueado-color"
          //           type="text"
          //           value={amaDeCasaTextDistrito}
          //           readOnly={true}
          //         />
          //       </div>
          //     </div>
          //   </>
          <>
            {/* En caso de false ama de casa, entonces mostrar opciones */}

            {inputsInformacionLaboral.map((input, index) => (
              <div key={index}>
                {index !== 0 && <hr></hr>}

                {/* Row */}
                <div className="row mb-3">
                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Lugar de trabajo: </label>{" "}
                      {!inputsInformacionLaboral[index]?.lab_nombre && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}
                    </p>

                    <input
                      name="lab_nombre"
                      className="w-100 input-form"
                      type="text"
                      value={input.lab_nombre || ""}
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(index, e)
                      }
                      maxLength={limiteCaracteresLugarTrabajo}
                    />
                  </div>

                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Teléfono: </label>{" "}
                      {!inputsInformacionLaboral[index]?.lab_telefono && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}{" "}
                      {inputsInformacionLaboral[index]?.lab_telefono?.length <
                        8 && (
                        <span style={{ color: "red" }}># con 8 digitos</span>
                      )}
                    </p>

                    <MaskedInput
                      mask={MaskTelefono}
                      guide={false} // Indica que no muestre automáticamente los guiones
                      id="lab_telefono"
                      name="lab_telefono"
                      placeholder="____-____"
                      value={input.lab_telefono || ""}
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "lab_telefono"
                        )
                      }
                      className="input-form"
                      maxLength={9}
                    />
                  </div>

                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Puesto: </label>{" "}
                      {!inputsInformacionLaboral[index]?.id_puesto && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}
                    </p>

                    <Select
                      name="id_puesto"
                      options={listPuesto}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "id_puesto"
                        )
                      }
                      placeholder="Buscar"
                      styles={customStylesSelect}
                    />
                  </div>
                </div>

                {/* Row */}
                <div className="row mb-3">
                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Provincia: </label>{" "}
                      {!inputsInformacionLaboral[index]?.provinciaLaboral && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}
                    </p>

                    <Select
                      name="provinciaLaboral"
                      options={listProvinciasLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "provincia",
                          undefined,
                          true
                        )
                      }
                      value={textProvinciaLaboral1}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                    />
                  </div>

                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Cantón: </label>{" "}
                      {!inputsInformacionLaboral[index]?.cantonLaboral && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}
                    </p>

                    <Select
                      name="canton-select"
                      options={listCantonLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "canton",
                          undefined,
                          true
                        )
                      }
                      value={textCantonLaboral1}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                  </div>

                  <div className="col-md-12 col-lg-4 mb-3">
                    <p className="mb-3">
                      <label>Distrito: </label>{" "}
                      {!inputsInformacionLaboral[index]?.id_localizacion && (
                        <span style={{ color: "red" }}>Requerido</span>
                      )}
                    </p>

                    <Select
                      name="distrito-select"
                      options={listDistritoLaboral}
                      className="basic-multi-select mb-3 maxwidth select-form"
                      onChange={(e) =>
                        handleInputChangeInformacionLaboral(
                          index,
                          e,
                          "distrito",
                          undefined,
                          true
                        )
                      }
                      value={textDistritoLaboral1}
                      placeholder="Buscar"
                      styles={customStylesSelect}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        <h4 className="text-center mb-4">Referencias Familiares-Personales</h4>

        {/* Actualmente se permite minimo una referencia y máximo 3 */}
        {/* Los datos se guardan y validan en un array */}
        {/* A continuación se hace un map de ese array para mostrar los datos */}
        {inputsReferencias.map((input, index) => (
          <div key={index}>
            {index !== 0 && <hr></hr>}

            {/* Row */}
            <div className="row mb-3">
              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Nombre: </label>{" "}
                  {!inputsReferencias[index]?.ref_nombre && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                <input
                  name="ref_nombre"
                  className="w-100 input-form"
                  type="text"
                  value={input.ref_nombre || ""}
                  onChange={(e) => handleInputChangeReferencias(index, e)}
                  maxLength={limiteCaracteresNombreReferencia}
                />
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Primer apellido: </label>{" "}
                  {!inputsReferencias[index]?.ref_apellido1 && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                <input
                  name="ref_apellido1"
                  className="w-100 input-form"
                  type="text"
                  value={input.ref_apellido1 || ""}
                  onChange={(e) => handleInputChangeReferencias(index, e)}
                  maxLength={limiteCaracteresApellido1Referencia}
                />
              </div>

              <div className="col-md-12 col-lg-4 mb-3">
                <p className="mb-3">
                  <label>Segundo apellido: </label>{" "}
                  {!inputsReferencias[index]?.ref_apellido2 && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                <input
                  name="ref_apellido2"
                  className="w-100 input-form"
                  type="text"
                  value={input.ref_apellido2 || ""}
                  onChange={(e) => handleInputChangeReferencias(index, e)}
                  maxLength={limiteCaracteresApellido2Referencia}
                />
              </div>
            </div>

            {/* Row */}
            <div className="row mb-3">
              <div className="col-md-12 col-lg-6 mb-3">
                <p className="mb-3">
                  <label>Parentesco: </label>{" "}
                  {!inputsReferencias[index]?.id_parentesco && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}
                </p>

                <Select
                  name="id_parentesco"
                  options={listParentesco}
                  className="basic-multi-select mb-3 maxwidth select-form"
                  onChange={(e) => handleInputChangeReferencias(index, e)}
                  placeholder="Buscar"
                  styles={customStylesSelect}
                />
              </div>

              <div className="col-md-12 col-lg-6 mb-3">
                <p className="mb-3">
                  <label>Teléfono/Celular: </label>{" "}
                  {!inputsReferencias[index]?.ref_tel_cel && (
                    <span style={{ color: "red" }}>Requerido</span>
                  )}{" "}
                  {inputsReferencias[index]?.ref_tel_cel?.length < 8 && (
                    <span style={{ color: "red" }}># con 8 digitos</span>
                  )}
                </p>

                <MaskedInput
                  mask={MaskTelefono}
                  guide={false} // Indica que no muestre automáticamente los guiones
                  id="ref_tel_cel"
                  name="ref_tel_cel"
                  placeholder="____-____"
                  value={input.ref_tel_cel || ""}
                  onChange={(e) => handleInputChangeReferencias(index, e)}
                  className="input-form"
                  maxLength={9}
                />
              </div>

              <div className="text-center mt-3">
                {/* Se envia el numero de la referencia 0, 1 o 2 */}
                {/* Minimo una referencia */}
                <button
                  className="mb-4 button-enviar"
                  onClick={() => removeInputsReferencias(index)}
                >
                  Eliminar referencia
                </button>
              </div>
            </div>
          </div>
        ))}

        <div className="text-center mt-3">
          {/* Se agrega un nuevo valor al array. Minimo 1 y maximo 3 */}
          <button
            className=" mb-4 button-anadir"
            onClick={addNewInputsReferencias}
          >
            Agregar otra referencia
          </button>
        </div>

        {/* Requerido para poder enviar datos al API*/}
        {/* Se adapta responsive. Se traslada el check para debajo del texto en dispositivos con pantalla pequeña */}
        <div className="mb-3 disclaimer-solicitud">
          {errorChecked && <spam style={{ color: "red" }}>{errorChecked}</spam>}
        </div>

        <div className=" row disclaimer-solicitud input-form">
          <div className="col-lg-1 disclaimer-chk">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleChangeDisclaimer}
            />
          </div>

          <div className="col-lg-11 disclaimer-p">{textDisclaimer}</div>
        </div>

        <div className="text-center mt-3">
          <button
            onClick={handleClickButtonEnviarSolicitud}
            className=" mb-4 button-enviar"
          >
            Enviar Solicitud
          </button>
        </div>
      </div>{" "}
      {/* Fin form-container */}
      {/* Footer */}
      <Footer src={logo} alt={"Logo Credie"} />
    </div> // Fin main-container
  ); // Fin return
}
