//librerias
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//Librerias
import axios from "axios";
import Swal from "sweetalert2";

//Rutas apis
import services from "../services/services";

//Se importan states de redux
import { setEstadoCargandoTrue, setEstadoCargandoFalse } from "../store";

//Funciones JS para usar en los formularios
export const useSolicitudStore = () => {
  const navigate = useNavigate();

  //Se setea función para usar los states de redux
  const dispatch = useDispatch();

  //Se setea función para usar la navegación entre paginas
  //const navigate = useNavigate();

  //Función para devolver los datos a cargar en los selects
  const listFirstDataSolicitud = async () => {
    //Se setea el state del spinner
    dispatch(setEstadoCargandoTrue());

    const id_m_solicitud = localStorage.getItem("id-pre-solicitud-mc"); //1826902;
    const token = localStorage.getItem("data-mc-c");

    if (id_m_solicitud !== "") {
      try {
        const headerAxiosListar = {
          "Content-Type": "application/json",
          Authorization: token,
        };

        const { data } = await axios.post(
          services.API.Form.listFirstDataSolicitud,
          {
            id_m_solicitud: id_m_solicitud,
          },
          { headers: headerAxiosListar }
        );

        if (data.respuesta_tipo === "success") {
          //Se setea el state del spinner
          dispatch(setEstadoCargandoFalse());

          return data;
        } else if (data.respuesta_tipo === "warning") {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        } else if (data.respuesta_tipo === "error") {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
      } catch (error) {
        //Se setea el state del spinner
        dispatch(setEstadoCargandoFalse());

        await Swal.fire(
          "Mensaje de información",
          "Lo sentimos. No se pudieron cargar los datos del siguiente formulario."
        );
      }
    } else {
      navigate("/");
    }
  };

  //Función para enviar datos del formulario para crear una Solicitud
  const sendDataSolicitud = async ({
    id_m_solicitud,
    msol_monto_solicitado,
    id_pais_nacimiento,
    id_nacionalidad,
    id_estado_civil,
    per_direccion,
    trabajos,
    referencias,
    idLocalizacion,
  }) => {
    const token = localStorage.getItem("data-mc-c");

    try {
      const headerAxiosListar = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const { data } = await axios.post(
        services.API.Form.sendDataSolicitud,
        {
          id_m_solicitud,
          msol_monto_solicitado,
          id_pais_nacimiento,
          id_nacionalidad,
          id_estado_civil,
          per_direccion,
          trabajos,
          referencias,
          idLocalizacion,
        },
        { headers: headerAxiosListar }
      );

      if (data.respuesta_tipo === "success") {
        await Swal.fire("Mensaje de información", data.respuesta_detalle);

        navigate("/");
      } else if (data.respuesta_tipo === "warning") {
        await Swal.fire("Mensaje de información", data.respuesta_detalle);
      } else if (data.respuesta_tipo === "error") {
        await Swal.fire("Mensaje de información", data.respuesta_detalle);
      } else {
        await Swal.fire("Mensaje de información", data.respuesta_detalle);
      }
    } catch (error) {
      await Swal.fire(
        "Verifica tus datos",
        //'Lo sentimos. Ha ocurrido un error al procesar los datos enviados.',
        error.message
      );
    }
  };

  //Funciones retornadas
  return {
    listFirstDataSolicitud,
    sendDataSolicitud,
  };
};
