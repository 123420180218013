import { configureStore } from '@reduxjs/toolkit';
import { 
    authSlice
 } from './';

//Se exporta store con todas las funciones de Redux creadas
export const store = configureStore({
    reducer: {
        auth:     authSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
