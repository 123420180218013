let ambient = "";

//Descomentar el ambiente a utilizar y comentar los que no

//ambient = "Dev";
//ambient = "QA";
ambient = "Prod"; 



let api_route_multi;
let base;
//Se asigna la ruta del API segun el ambiente
switch (ambient) {
  case "Dev":
    //api_route_multi = "http://10.161.252.104:80/SOLICITUDCREDITO/INSTACR/Api_EKN/";
    api_route_multi = "http://localhost:22815/";
    base = '' ;
    break;
  case "QA":
    api_route_multi = "http://10.161.252.104:80/SOLICITUDCREDITO/INSTACR/Api_EKN/";
    base = '' ;
    break;
  case "Prod":
    api_route_multi = "http://solicitudescredie.instacredit.com:1103/Pro/";
   base = 'Api_Multiconvenios_Solicitud/';
   break;
}

//Lista de APIS y Endpoints
 //base = 'Api_Multiconvenios_Solicitud/'
//let
let API = {
  Form: {
    listFirstDataSelects:
      api_route_multi +base+"Presolicitud_Obtener",
    dataCedula:
      api_route_multi +base+"Presolicitud_ConsultaId",
    sendDataPreSolicitud:
      api_route_multi +base+"Presolicitud_Enviar",
    listFirstDataSolicitud:
      api_route_multi +base+"Solicitud_Obtener",
    sendDataSolicitud:
      api_route_multi +base+"Solicitud_Enviar",
  },
};
////
export default {
  API: API,
};
