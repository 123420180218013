import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

//Components
import App from './App';

//Redux
import { store } from './store';

//React-Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//Se renderiza todo el sistema en el div root, del archivo index.html que está en la carpeta public
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  //Se estabelce que todo App podrá usar los states de Redux
  <Provider store={ store }>
 
      <App />
 
  </Provider>
);