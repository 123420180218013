import React from 'react'

export default function HeaderImagen(params) {
  return (
    <>

<div className="header-banner-img mb-3">
       <picture>
         {/* IMG -> Desktop, iPads, tablets */}
         <source
           srcSet={params.banner}
           media="(min-width: 768px)"
           style={{ maxWidth: '100%', height: 'auto' }}
         />
         {/* IMG -> Mobile */}
         <img
           src={params.bannerMobile}
           alt={params.alt}
           style={{ maxWidth: '100%', height: 'auto' }}
         />
       </picture>
     </div>
      
    </>
  )
}
