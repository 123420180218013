import { createSlice } from '@reduxjs/toolkit';

//True se usará para mostrar el spinner y false para quitarlo
export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        estadoCargando: false,
    },
    reducers: {
        setEstadoCargandoTrue: ( state ) => {
            state.estadoCargando = true;
        },
        setEstadoCargandoFalse: ( state ) => {
            state.estadoCargando = false;
        } 
    }
});



//Las acciones creadas son generadas para cada función del reducer
export const { setEstadoCargandoTrue, setEstadoCargandoFalse } = authSlice.actions;