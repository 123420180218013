//librerias
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//Librerias
import axios from "axios";
import Swal from "sweetalert2";

//Rutas apis
import services from "../services/services";

//Se importan states de redux
import { setEstadoCargandoTrue, setEstadoCargandoFalse } from "../store";

//Funciones JS para usar en los formularios
export const usePresolicitudStore = () => {
  const navigate = useNavigate();

  //Se setea función para usar los states de redux
  const dispatch = useDispatch();

  //Se setea función para usar la navegación entre paginas
  //const navigate = useNavigate();

  //Función para devolver los datos a cargar en los selects
  const listFirstDataSelects = async () => {
    //Se setea el state del spinner
    dispatch(setEstadoCargandoTrue());

    localStorage.setItem("id-pre-solicitud-mc", "");
    localStorage.setItem("lista-provincias-solicitud-mc", "");
    localStorage.setItem("lista-canton-solicitud-mc", "");
    localStorage.setItem("lista-distrito-solicitud-mc", "");

    const token = localStorage.getItem("data-mc-c");

    try {
      const headerAxiosListar = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const { data } = await axios.post(
        services.API.Form.listFirstDataSelects,
        {},
        { headers: headerAxiosListar }
      );

      if (data.respuesta_tipo === "success") {
        //Se setea el state del spinner
        dispatch(setEstadoCargandoFalse());

        return data;
      }
    } catch (error) {
      //Se setea el state del spinner
      dispatch(setEstadoCargandoFalse());

      await Swal.fire(
        "Verifica tus datos",
        "Lo sentimos. No se pudieron cargar los datos iniciales del formulario."
      );
    }
  };

  //Función para devolver los datos a cargar al digitar la cedula
  const dataCedula = async (cedula) => {
    const token = localStorage.getItem("data-mc-c");

    try {
      const headerAxiosListar = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const { data } = await axios.post(
        services.API.Form.dataCedula,
        {
          identificacion: cedula,
          numeroAsegurado: cedula,
        },
        { headers: headerAxiosListar }
      );

      if (data.respuesta_tipo === "success") {
        if (data.data.solicitud_en_curso === true) {
          //se guarda el id para usarlo en la Solicitud
          localStorage.setItem("id-pre-solicitud-mc", data.data.id_m_solicitud);

          await Swal.fire({
            title: "Mensaje de información",
            text: "Será redirigido a completar la Solicitud ya que anteriormente habia completado la PreSolicitud.",
            confirmButtonColor: "#001642",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/solicitud");
            }
          });

          //da tiempo mientras se recarga la pagina y se limpia el formulario
          const a = setTimeout(() => {
            console.log("");
          }, 3);
        } else {
          return data;
        }
      } else if (data.respuesta_tipo === "warning") {
        if (data.respuesta_detalle === "La persona posee solicitudes activas") {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (
          data.respuesta_detalle.includes(
            "ya contas con una linea Credie registrada"
          )
        ) {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          return data;
        }
      } else if (data.respuesta_tipo === "error") {
        await Swal.fire({
          title: "Mensaje de información",
          text: data.respuesta_detalle,
          confirmButtonColor: "#001642",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });

        return data;
      }
    } catch (error) {
      await Swal.fire({
        title: "Mensaje de información",
        text: error.message,
        confirmButtonColor: "#001642",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          ////;
          //navigate('/');
          window.location.reload();
        }
      });
    }
  };

  //Función para devolver los datos a cargar al digitar la cedula y el numero de asegurado para extranjeros
  const dataCedulaNumeroAsegurado = async (cedula, numeroAsegurado) => {
    const token = localStorage.getItem("data-mc-c");

    try {
      const headerAxiosListar = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const { data } = await axios.post(
        services.API.Form.dataCedula,
        {
          identificacion: cedula,
          numeroAsegurado: numeroAsegurado,
        },
        { headers: headerAxiosListar }
      );

      if (data.respuesta_tipo === "success") {
        if (data.data.solicitud_en_curso === true) {
          //se guarda el id para usarlo en la Solicitud
          localStorage.setItem("id-pre-solicitud-mc", data.data.id_m_solicitud);

          await Swal.fire({
            title: "Mensaje de información",
            text: "Será redirigido a completar la Solicitud ya que anteriormente habia completado la PreSolicitud.",
            confirmButtonColor: "#001642",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/solicitud");
            }
          });
        } else {
          return data;
        }
      } else if (data.respuesta_tipo === "warning") {
        if (data.respuesta_detalle === "La persona posee solicitudes activas") {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (
          data.respuesta_detalle.includes(
            "ya contas con una linea Credie registrada"
          )
        ) {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          return data;
        }
      } else if (data.respuesta_tipo === "error") {
        await Swal.fire({
          title: "Mensaje de información",
          text: data.respuesta_detalle,
          confirmButtonColor: "#001642",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });

        return data;
      }
    } catch (error) {
      await Swal.fire({
        title: "Mensaje de información",
        text: error.message,
        confirmButtonColor: "#001642",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  };

  //Función para enviar datos del formulario para crear una PreSolicitud
  const sendDataPreSolicitud = async ({
    codigo_sucursal,
    cedula,
    nombre,
    apellido1,
    apellido2,
    nacimiento,
    id_perdoc,
    correo,
    telefono,
    idActividadEconomica,
    idLocalizacion,
    numeroAsegurado,
    per_genero,
  }) => {
    const token = localStorage.getItem("data-mc-c");

    try {
      const headerAxiosListar = {
        "Content-Type": "application/json",
        Authorization: token,
      };

      const { data } = await axios.post(
        services.API.Form.sendDataPreSolicitud,
        {
          codigo_sucursal,
          cedula,
          nombre,
          apellido1,
          apellido2,
          nacimiento,
          id_perdoc,
          correo,
          telefono,
          idActividadEconomica,
          idLocalizacion,
          numeroAsegurado,
          per_genero,
        },
        { headers: headerAxiosListar }
      );

      if (data.respuesta_tipo === "success") {
        if (data.data.avanza_a_solicitud === true) {
          //se guarda el id para usarlo en la Solicitud
          localStorage.setItem("id-pre-solicitud-mc", data.data.id_m_solicitud);

          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Continuar",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/solicitud");
            }
          });
        } else {
          await Swal.fire({
            title: "Mensaje de información",
            text: data.respuesta_detalle,
            confirmButtonColor: "#001642",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
              window.location.reload();
            }
          });
        }
      } else if (data.respuesta_tipo === "warning") {
        if (data.respuesta_detalle === "La solicitud ha sido Rechazada.") {
          //mensaje personalizado
          await Swal.fire("Mensaje de información", data.respuesta_detalle);
        } else {
          await Swal.fire("Mensaje de información", data.respuesta_detalle);
        }
      } else {
        await Swal.fire("Mensaje de información", data.respuesta_detalle);
      }
    } catch (error) {
      await Swal.fire(
        "Verifica tus datos",
        "Lo sentimos. Ha ocurrido un error al procesar los datos enviados."
      );
    }
  };

  //Funciones retornadas
  return {
    listFirstDataSelects,
    dataCedula,
    dataCedulaNumeroAsegurado,
    sendDataPreSolicitud,
  };
};
